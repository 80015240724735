export function addEventListener (dom, eventType, func, options = false) {
  dom.addEventListener(eventType, func, options)
}

export function removeListener (dom, eventType, func) {
  dom.removeEventListener(eventType, func)
}

export function getTargetDom (dom, parentDom) {
  const html = document.documentElement
  const body = document.body
  while (dom !== html && dom !== body && dom.parentNode !== parentDom) {
    dom = dom.parentNode
  }
  return dom
}

export function getTargetAttr (dom, attrName) {
  const html = document.documentElement
  const body = document.body
  let i = 0 // 限制查找次数
  let attrVal
  do {
    i++
    attrVal = dom.getAttribute(attrName)
    dom = dom.parentNode
  } while (dom && dom !== html && dom !== body && i <= 5 && !attrVal)
  return attrVal
}

export function getScrollEventTarget (element) {
  let currentNode = element
  while (currentNode && currentNode.tagName !== 'HTML' && currentNode.nodeType === 1) {
    const overflowY = document.defaultView.getComputedStyle(currentNode).overflowY
    if (overflowY === 'scroll' || overflowY === 'auto') {
      return currentNode
    }
    currentNode = currentNode.parentNode
  }
  return window
}

export function urlQuery () {
  const result = {}
  const query = window.location.href.split('?')[1]
  const search = (query && decodeURI(query)) || ''
  if (search) {
    search.split('&').forEach(item => {
      const data = item.split('=')
      result[encodeURI(data[0])] = encodeURI(data[1])
    })
  }
  return result
}

export function throttle (func, delay = 100, immediate = true) {
  let isHande = false
  return function handleThrottle () {
    if (isHande) return
    const argus = Array.prototype.slice.call(arguments)
    if (immediate) {
      func.apply(this, argus)
      immediate = false
    }
    isHande = true
    setTimeout(() => {
      func.apply(this, argus)
      isHande = false
    }, delay)
  }
}

export function debounce (func, delay = 100, gapTime = 100) {
  let timer = null
  let old = new Date().getTime()
  return function hanleDeBounce (...rest) {
    const now = new Date().getTime()
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    if (now - old >= gapTime) {
      old = now
      func.apply(this, rest)
    }
    timer = setTimeout(() => {
      func.apply(this, rest)
    }, delay)
  }
}

export const isPC = function () {
  if (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)) {
    return false
  } else {
    return true
  }
}

export const getComputedStyle = function (el, attr) {
  // 判断浏览器是否支持这个方法
  return window.getComputedStyle ? window.getComputedStyle(el, null)[attr] : el.currentStyle[attr]
}

export const _setInterval = function (func, gapTime) {
  let timer = null
  function _pause () {
    if (timer) {
      window.clearTimeout(timer)
      timer = null
    }
  }
  function _run (...rest) {
    _pause()
    timer = window.setTimeout(() => {
      func.apply(this, rest)
      _run.apply(this, rest)
    }, gapTime)
  }
  function _restart (...rest) {
    func.apply(this, rest)
    _run.apply(this, rest)
  }
  return {
    run: _run,
    pause: _pause,
    restart: _restart
  }
}

export const _touches = function (obj, direction, fun) {
  // obj:ID对象
  // direction:swipeleft,swiperight,swipetop,swipedown,singleTap,touchstart,touchmove,touchend
  //   划左， 划右， 划上， 划下，点击， 开始触摸， 触摸移动， 触摸结束
  // fun:回调函数
  var defaults = { x: 5, y: 5, ox: 0, oy: 0, nx: 0, ny: 0 }
  direction = direction.toLowerCase()
  _touches.target = obj
  // 配置：划的范围在5X5像素内当点击处理
  _touches.touchStartFunc = function (event) {
    defaults.ox = event.targetTouches[0].pageX
    defaults.oy = event.targetTouches[0].pageY
    defaults.nx = defaults.ox
    defaults.ny = defaults.oy
    if (direction.indexOf('touchstart') !== -1) fun(event)
  }
  _touches.touchMoveFunc = function (event) {
    event.preventDefault()
    defaults.nx = event.targetTouches[0].pageX
    defaults.ny = event.targetTouches[0].pageY
    var changeY = defaults.oy - defaults.ny
    var changeX = defaults.ox - defaults.nx
    if (direction.indexOf('touchmove') !== -1) {
      if (Math.abs(changeX) > Math.abs(changeY) && Math.abs(changeY) > defaults.y) {
        // 左右事件
        fun('line', changeX)
      } else if (Math.abs(changeY) > Math.abs(changeX) && Math.abs(changeX) > defaults.x) {
        // 上下事件
        fun('vertical', changeY)
      } else {
        // 点击事件
        if (direction.indexOf('singleTap') !== -1) fun()
      }
    }
  }
  _touches.touchEndFunc = function () {
    var changeY = defaults.oy - defaults.ny
    var changeX = defaults.ox - defaults.nx
    if (Math.abs(changeX) > Math.abs(changeY) && Math.abs(changeY) > defaults.y) {
      // 左右事件
      if (changeX > 0) {
        if (direction.indexOf('swipeleft') !== -1) fun()
      } else {
        if (direction.indexOf('swiperight') !== -1) fun()
      }
    } else if (Math.abs(changeY) > Math.abs(changeX) && Math.abs(changeX) > defaults.x) {
      // 上下事件
      if (changeY > 0) {
        if (direction.indexOf('swipetop') !== -1) fun()
      } else {
        if (direction.indexOf('swipedown') !== -1) fun()
      }
    } else {
      // 点击事件
      if (direction.indexOf('singleTap') !== -1) fun()
    }
    if (direction.indexOf('touchend') !== -1) fun()
  }
  obj.addEventListener('touchstart', _touches.touchStartFunc, false)
  obj.addEventListener('touchmove', _touches.touchMoveFunc, false)
  obj.addEventListener('touchend', _touches.touchEndFunc, false)
}

_touches.removeEventListener = function () {
  _touches.target.removeEventListener('touchstart', _touches.touchStartFunc)
  _touches.target.removeEventListener('touchmove', _touches.touchMoveFunc)
  _touches.target.removeEventListener('touchend', _touches.touchEndFunc)
}

export function isString (str) {
  return typeof str === 'string'
}

export function isDom (el) {
  // 鸭式辨形, 当然最准确的时用特性诊断, 因为有的不是DOM元素，也可以使用DOM方法，比如Fragment-碎片
  return el && typeof el.className === 'string'
}

export function hasclass (el, cls) {
  return ~el.className.indexOf(cls)
}

export function addClassName (el, cls) {
  if (isDom(el) && isString(cls) && !hasclass(el, cls)) {
    el.className += ` ${cls}`
  }
}

export function removeClassName (el, cls) {
  if (isDom(el)) {
    el.className = el.className.replace(cls, '')
  }
}

export function toggleClassName (el, cls) {
  if (isDom(el)) {
    if (~el.className.indexof(cls)) {
      removeClassName(el, cls)
    } else {
      addClassName(el, cls)
    }
  }
}

/**
 * @description 获取cookie
 * @param name
 * @returns {string}
 */
export function getCookie (name) {
  if (document.cookie.length > 0) {
    let start = document.cookie.indexOf(name + '=')
    if (start !== -1) {
      start = start + name.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}

export function setCookie (key, value, exp) {
  if (exp && exp instanceof Date) {
    document.cookie = `${key}=${escape(value)};path=/;expires=${exp.toUTCString()}`
  } else {
    document.cookie = `${key}=${escape(value)};path=/`
  }
}

