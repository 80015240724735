import $ from 'jquery'
import { SysInfos, URLS } from '../../configs'

const locations = window.location
const port = locations.port
const hostname = 'cm.bilibili.com'
const BaseUrlPrefix = locations.protocol + '//' + hostname + (process.env.NODE_ENV === 'production' ? '' : ':' + port)

const BASE_URL = (BaseUrlPrefix + (SysInfos.BASE_API_PATH || ''))

function initRequest (options = {}) {
  const defaultOptions = {
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    },
    // contentType: 'application/json; charset=UTF-8' // 部分手机的浏览器不支持此contentType的get请求
  }
  return function request (params = {}, config = {}) {
    options.type = options.type || 'get'
    if (options.type.toLocaleLowerCase() === 'post') {
      defaultOptions.contentType = 'application/json; charset=UTF-8'
      params = JSON.stringify(params)
    }
    return new Promise((resolve, reject) => {
      const option = Object.assign({}, defaultOptions, config)

      $.ajax({
        type: options.type,
        url: /^http(s)?:\/\/.+/i.test(options.url) ? options.url : (BASE_URL + options.url),
        data: params,
        ...option,
        success: function (data) {
          if (data.status === 'success') {
            resolve(data)
          } else {
            reject(data)
          }
        },
        error: function (err) {
          reject(err)
        }
      })
    })
  }
}

function generateRequest () {
  const Map = {}
  Object.keys(URLS).forEach(key => {
    Map[key] = initRequest(URLS[key])
  })
  return Map
}

export default generateRequest()
