/* global $ */
import './index.scss'
import HTML from './index.html'
import Http from '@/utils/http'
import { addEventListener } from '@/utils'
import { reportEvToService, getSourceBtn, clearSourceBtn } from '@/utils/monitor'

import Tip from '@/components/tip'

const Handlebars = require('handlebars')
const bodyDOM = document.body
const compile = Handlebars.compile(HTML)

let industries = []

$(function () {

  Http.getIndustries().then(res => {
    if (res.status === 'success') {
      industries = res.result
      industries.forEach(item => {
        $('#modelSelect').append('\<option\>'+item.first_industry_name+'\</option\>')
      })
      $('#modelSelect').selectpicker('refresh');
    }
  })
})

function verifyForms (params) {
  const telRegExp = /^1[3456789]\d{9}$/
  if (!Object.keys(params).map(key => params[key]).every(item => !!item)) {
    return Tip.warn('请填写完整！')
  } else if (!telRegExp.test(params.phone_number)) {
    return Tip.warn('手机号码格式错误！')
  }
  return true
}

function handleSubmitFunc (e) {
  const form = $('#ad-modal-form')[0]
  const desc = form.desc.value.trim()
  const descType = form.demand_type_modal.value.trim()
  const params = {
    name: form.username.value.trim(),
    phone_number: form.tel.value.trim(),
    company_name: form.company.value.trim(),
    industry: form.industry.value
  }
  let industry_id = 0
  for (let i =0;i<industries.length;i++) {
    if (industries[i].first_industry_name === form.industry.value) {
      industry_id = industries[i].first_industry_id
      break
    }
  }
  params.first_industry_id = industry_id
  if (verifyForms(params)) {
    if (descType) {
      params.save_type = descType
    }
    if (desc) {
      if (descType == null || descType === '') {
        return Tip.info('请选择需求类型')
      }
      params.requirement_desc = desc
    }
    reportEvToService(1, { is_click_success: 1 })
    params.source = 'PC' + getSourceBtn()


    params.clue_source_id = 2
    Http.recordAccountInfo(params).then(res => {
      clearFormVal(form)
      clearSourceBtn()
      $('#adModal').modal('hide')
      return Tip.info('提交成功')
    }).catch(err => {
      return Tip.error(err.message || '提交失败！')
    })
  }
}

function clearFormVal (formDOM) {
  formDOM.username.value = ''
  formDOM.tel.value = ''
  formDOM.company.value = ''
  formDOM.desc.value = ''
  $('input:radio[name=demand_type_modal]').attr('checked', false)
  $('#modelSelect').selectpicker('val', '')
}

function initEvent () {
  addEventListener($('#modal-submit-btn')[0], 'click', handleSubmitFunc)
}

export default {
  init () {
    const div = document.createElement('div')
    div.innerHTML = compile()
    bodyDOM.appendChild(div)
    $('#modelSelect').selectpicker('val', '')
    initEvent()
  }
}
