/* global $ */
import 'bootstrap'
import '@/assets/css/index.scss'
import 'bootstrap-select'
import 'bootstrap-select/dist/css/bootstrap-select.css'
import * as Alita from '@/utils/monitor'
import Modal from '@/components/modal'
import { isPC } from '@/utils'
import Vue from 'vue'
import VueHeader from './header.vue'

if (!isPC()) {
  window.location.replace('/mobile.html')
}

function initHead (el, options) {
  new Vue({
    el,
    components: { VueHeader },
    render: h => {
      return h('VueHeader', {
        props: {
          ...options
        }
      }, [])
    }
  })
}

export default {
  init (el, data = {}) {
    // 埋点
    Alita.init()
    Modal.init()
    initHead(el, data)
  }
}
