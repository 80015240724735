/* global XMLHttpRequest */
'use strict'

import {
  addEventListener,
  getTargetAttr,
  isPC,
  urlQuery
} from './index.js'
import Http from './http'

const sendEventData = function (data) {
  var url = window.location.protocol + '//cm.bilibili.com/meet/api/open_api/v1/marketing/report'
  var str = JSON.stringify(data)
  // var data = {
  //   "event":"click","url":"http://test/jj","ts":12323123231,"android_id":"213",
  //   "idfa":"idfa","imei":"imei","buvid":123213,"build_id":2131,"model":"2342","mid":1231232,
  //   "vendor":"vendor","network":"os","ad_cb":"XXXX"
  // }
  // 接口文档：https://info.bilibili.co/pages/viewpage.action?pageId=7545278
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, str)
  } else {
    var xhr = new XMLHttpRequest()
    xhr.open('POST', url, false) // 第三个参数表明是同步的 xhr
    // xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8') // 默认就是 text/plain
    xhr.send(str)
  }
}

export function formatSource () {
  const url = window.location.href
  const reg = /\/(\w+)\.html$/
  let source = ''
  if (url.includes('case')) {
    source = '成功案例'
  } else if (url.includes('observe')) {
    source = '动态观察'
  } else if (url.includes('product')) {
    source = '营销产品'
  } else {
    source = '首页'
  }

  if (url.includes('detail')) {
    source += `详情-${window.location.href.slice(-1)}`
  } else if (reg.test(url)) {
    source += `详情-${RegExp.$1}`
  }

  return source
}

// 0-'show' 1-'click' 2-'share';
export function reportEvToService (ev = 0, option = {}) {
  if (!token) return
  const Query = urlQuery()
  const isWX = Query.iswx
  const isBilibili = ('native.theme' in Query) && ('night' in Query)
  var data = {
    business_id: 1,
    platform_id: isPC() ? 1 : 2,
    ref: (document.referrer && document.referrer.split('?')[0]) || (isWX && 'weixin_share_link') || (isBilibili && 'bilibili_app') || '',
    event_type: ev,
    source: formatSource(),
    token,
    ...option
  }
  if (ev === 1 && !Object.prototype.hasOwnProperty.call(data, 'button_name')) {
    data.button_name = sourceBtn
  }
  sendEventData(data)
}

function getToken () {
  return (window.localStorage && window.localStorage.getItem('EBToken')) || ''
}

export function saveTokenTo () {
  token = getToken()
  if (!token) {
    Http.getAccountToken().then(res => {
      if (res.status === 'success') {
        token = res.result
        window.localStorage && window.localStorage.setItem('EBToken', res.result)
      }
      reportEvToService()
    }).catch(() => {
      reportEvToService()
    })
  } else {
    reportEvToService()
  }
}

// 逻辑修改：提交表单新增来源字段 --  点击哪个按钮弹出表单并提交的，因此埋点不需要了，新逻辑修改如下
let sourceBtn = ''; let token = ''
function updateSourceBtn (e) {
  const attr = getTargetAttr(e.target, 'data-monitor')
  if (attr != null) {
    sourceBtn = attr
    reportEvToService(1, { button_name: attr })
  }
}

export const init = function () {
  addEventListener(document.body, 'click', updateSourceBtn)
  saveTokenTo()
}

export const getSourceBtn = function () {
  return sourceBtn
}

export const clearSourceBtn = function () {
  sourceBtn = ''
}
