import './index.scss'
import HTML from './index.html'
const Handlebars = require('handlebars')
const bodyDOM = document.body
const compile = Handlebars.compile(HTML)
const config = ['info', 'warn', 'error']
function generateTip () {
  const result = {}
  config.forEach(type => {
    result[type] = content => {
      const div = document.createElement('div')
      div.innerHTML = compile({ type, content })
      bodyDOM.appendChild(div)
      setTimeout(() => {
        bodyDOM.removeChild(div)
      }, 1000)
    }
  })
  return result
}

export default generateTip()
