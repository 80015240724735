<template>
  <div id="header-nav">
    <HeaderLogin
      :isLogin="isLogin"
      :isGrey="isGrey"
      :account="user"
      v-bind="$props"
    ></HeaderLogin>
  </div>
</template>

<script>
import { urlQuery, getCookie, setCookie } from "@/utils";
import Http from "@/utils/http";
import HeaderLogin from "./header-login.vue";

const BidGrey = "bid_grey";
export default {
  name: "header",
  props: ["pageName", "monitor"],
  components: { HeaderLogin },
  data() {
    return {
      isGrey: true,
      isLogin: false,
      user: {}
    };
  },
  created() {
    // this.checkGreyVal()
    this.fetchLoginStatus();
  },
  methods: {
    checkGreyVal() {
      const query = urlQuery();
      const grey = getCookie(BidGrey);
      if (+grey === 1) {
        return (this.isGrey = true);
      }
      if (+query[BidGrey] === 1) {
        setCookie("bid_grey", "1");
        return (this.isGrey = true);
      }
      return (this.isGrey = false);
    },
    fetchLoginStatus() {
      Http.getLoginStatus()
        .then((res = {}) => {
          this.user = res.result || {};
          this.isLogin = true;
        })
        .catch(err => {
          this.isLogin = false;
        });
    }
  }
};
</script>

<style lang="scss"></style>
