<template>
  <div id="header-login">
    <VulNavigation
      ref="navigation"
      @click-nav="handleNavClick"
      @sub-click-nav="handleSubNavClick"
      title=""
      :navs="navs"
      theme="light"
      :nickname="account.name"
      :bid="`${account.bid}`"
      :avatar="account.avatar"
      :transparent="isTransparent"
      @logout="logout"
      :visitor="!isLogin"
    >
      <template v-slot:logo>
        <a class="nav-link" href="https://www.bilibili.com" target="_blank">
          <img class="header-logo" :src="logoUrl" alt="logo" />
        </a>
      </template>
      <template v-if="isLogin" v-slot:right>
        <a
          href="https://e.bilibili.com/site"
          :data-monitor="monitor + '进入管理平台'"
          target="_blank"
          :class="['btn header-btn', { white: isWhite }]"
        >
          进入管理平台
        </a>
      </template>
      <template v-else v-slot:userinfo>
        <button
          type="button"
          @click="toLogin"
          :data-monitor="monitor + '登录/注册'"
          style="border: none"
          class="btn btn-primary login-btn"
        >
          登录/注册
        </button>
      </template>
    </VulNavigation>
  </div>
</template>

<script>
import { throttle } from '@/utils'
import Tip from '@/components/tip'
import logoBlue from '@/assets/images/logo/logoblue.svg'
import logoWhite from '@/assets/images/logo/logowhite.svg'
import { HeaderOptions, homeLinkList } from '@/constants/index.js'
const header = window.vulNavigation
export default {
  name: 'header-login',
  props: ['pageName', 'monitor', 'account', 'isLogin'],
  components: { VulNavigation: header.VulNavigation },
  data() {
    return {
      navs: HeaderOptions.routerMap
        .map(item => ({
          ...item,
          id: item.key,
          auth: true
        }))
        .concat([
          {
            name: '营销平台',
            key: 'platform',
            auth: true,
            children: homeLinkList.map((item, index) => ({
              ...item,
              id: index,
              auth: true
            }))
          },
          {
            name: '营销学院',
            href:
              '//page.ma.scrmtech.com/cyy-form/index?pf_uid=33382_2203&id=16936&main_id=33382&wx_id=2203&lang=zh-CN',
            id: 'study',
            auth: true
          },
          {
            name: '合作伙伴专区',
            href:
              '//e.bilibili.com/main/collaborator',
            id: 'agent',
            auth: true
          }
        ]),
      mouseOver: false,
      isTransparent: this.pageName === 'home'
    }
  },
  computed: {
    isWhite({ mouseOver, isTransparent }) {
      return !mouseOver && isTransparent
    },
    logoUrl({ isWhite }) {
      return isWhite ? logoWhite : logoBlue
    }
  },
  mounted() {
    this.setNavActive()
    this.handleHomeHeaderStyle()
  },
  methods: {
    toLogin() {
      window.open('//e.bilibili.com/site/login?system_type=marking', '_blank')
    },
    logout(res) {
      const { code, message } = res
      //  0-code代表成功, 非0-code代表失败
      if (code === 0) {
        // 有需求的可以调用自身退出接口
        location.href = location.href
      } else {
        // 显示错误信息
        Tip.error('登出失败！')
      }
    },
    setNavActive() {
      this.$refs.navigation.setActive(this.pageName)
    },
    handleNavClick(item) {
      if (item.path) {
        window.location.href = '//' + window.location.host + item.path
      }
      if (item.href) {
        window.location.href = item.href
      }
    },
    handleSubNavClick(item) {
      window.open(item.path, '_blank')
    },
    handleNavsUI() {
      const handleMouseEnter = function() {
        this.mouseOver = true
      }.bind(this)
      const handleMouseLeave = function() {
        this.mouseOver = false
      }.bind(this)
      $('#header-login').on('mouseenter', handleMouseEnter)
      $('#header-login').on('mouseleave', handleMouseLeave)
      this.$once('hook:beforeDestroy', () => {
        $('#header-login').off('mouseenter', handleMouseEnter)
        $('#header-login').off('mouseleave', handleMouseLeave)
      })
    },
    handleHomeHeaderStyle() {
      if (this.pageName !== 'home') return
      const updateNavStyle = throttle(
        function() {
          const video = $('video')
          const header = $('#header-login')
          const logo = $('.header-logo')
          const threshold = video.height() - header.height()
          if (Math.abs(video.offset().top) > threshold) {
            this.isTransparent = false
          } else {
            this.isTransparent = true
          }
        }.bind(this)
      )
      $('body').on('scroll', updateNavStyle)
      this.$once('hook:beforeDestroy', () => {
        $('body').off('scroll', updateNavStyle)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
#header-login {
  position: fixed;
  background-color: transparent;
  z-index: 99;
  width: 100%;
  min-width: $ad-min-gride-width;
  .header-logo {
    height: 32px;
  }
  .vul-navigation {
    .nav-container {
      .nav-item {
        .nav-item-name:after {
          height: 2px;
          border-radius: 0;
        }
        &:hover,
        &.active {
          .nav-item-name {
            color: #00a1d6;
            font-weight: 700;
          }
          .nav-item-name:after {
            background-color: #00a1d6;
          }
          .subnav-item {
            font-weight: normal;
            color: #212529;
          }
        }
        .subnav-item {
          font-weight: normal;
          color: #212529;
        }
      }
    }
    &.transparent {
      box-shadow: none;
      background-color: transparent;
      .nav-item-name:after {
        background: #fff;
      }
      .more-hover {
        .iconfont {
          color: #ffffff;
          opacity: 0.8;
        }
        &:hover {
          .iconfont {
            color: #ffffff;
            opacity: 1;
          }
        }
      }
      .line {
        background-color: rgba(255, 255, 255, 0.3);
      }
      .user-hover {
        .userinfo {
          .nickname,
          .bid {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .icon-el-icon-caret-bottom {
          color: rgba(255, 255, 255, 0.8);
        }
        &:hover {
          .userinfo {
            .nickname,
            .bid {
              color: rgba(255, 255, 255, 1);
            }
          }
          .icon-el-icon-caret-top {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .nav-container {
        .nav-item {
          .nav-item-name {
            color: #fff;
          }
          &:hover,
          &.active {
            .nav-item-name {
              color: #fff;
              font-weight: 700;
            }
            .nav-item-name:after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .header-btn {
    width: 116px;
    padding: 0;
    line-height: 36px;
    color: #333333;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    &:hover {
      background-color: #fafafa;
    }
    &.white {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.3) !important;
      border-color: rgba(255, 255, 255, 0.4) !important;
      box-shadow: 0px 0px 20px rgba(12, 142, 255, 0.05);
    }
  }
  .login-btn {
    width: 100px;
    padding: 0;
    margin-left: 6px;
    line-height: 36px;
    border-radius: 4px;
  }
}
</style>
