import headerIcon from '../assets/images/logo.svg'

const IMGS = {
  gif_1_2: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/2rsumOZsI4.gif',
  gif_2_1_1: 'https://activity.hdslb.com/blackboard/static/20210510/cffd107c73fc638c142399e72cb0cb0f/4SEStGaA7R.gif',
  gif_2_1: 'https://activity.hdslb.com/blackboard/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/gif_2_1.gif',
  gif_3_1: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/HQCdMCt5id.gif',
  gif_6_2: 'https://activity.hdslb.com/blackboard/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/gif_6_2.gif',

  bg_1_1_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/s1MEXXvEd.png',
  bg_1_2_1: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/SMZkV0w1F7.gif',
  bg_1_2_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/qkVgHXbdJ.png',

  bg_2_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/BI2hINvyd.png',
  bg_2_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/nKR-wqI~X.png',
  bg_2_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/Sa0B~P3sv.png',

  bg_3_1_1: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/NNihPi0reh.gif',
  bg_3_1_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/1JyBhFx4n.png',
  bg_3_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/OV6tECBM9.png',

  bg_4_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/SWOeIN4H~.jpg',
  bg_4_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/5R89QLHOd.jpg',
  bg_4_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/qxgw8bm82.jpg',

  bg_5_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/8kq8yVHn4.png',
  bg_5_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/Ig26C4fA4.png',
  bg_5_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/2tQM-xdsv.png',
  bg_5_4: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/cffd107c73fc638c142399e72cb0cb0f/m868qA3Ev.png',

  bg_6_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_6_1.png',
  bg_6_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_6_2.png',
  bg_6_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_6_3.png',

  bg_7_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_7_1.jpg',
  bg_7_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_7_2.png',
  bg_7_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_7_3_1.jpg',
  bg_7_4: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_7_4.jpg',

  bg_8_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_8_1.png',
  bg_8_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_8_2.png',
  bg_8_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/bg_8_3.png',

  banner1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/a_left_banner.png',
  banner2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/a_right_banner.png',
  banner3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/b_left_banner.png',
  banner4: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/b_right_banner.png',

  AFK_Arena_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/AFK_Arena_logo.png',
  AFK_Arena_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/AFK_Arena_banner.jpg',
  AFK_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/AFK_tel_banner.png',

  HZ_blue_qb_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/HZ_blue_qb_logo.png',
  HZ_blue_qb_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/HZ_blue_qb_banner.jpg',
  HZ_qb_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/HZ_qb_tel_banner.png',

  liantong_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/liantong_logo.jpg',
  liantong_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/liantong_banner.jpg',
  liantong_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/liantong_tel_banner.png',

  lagou_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/lagou_logo.png',
  lagou_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/lagou_banner.jpg',
  lagou_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/lagou_tel_banner.png',

  qidian_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/qidian_logo.jpg',
  qidian_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/qidian_banner.jpg',
  qidian_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/qidian_tel_banner.png',

  kfc_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/kfc_logo.png',
  kfc_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/kfc_banner.jpg',
  kfc_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/kfc_tel_banner.png',

  fengda_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/fengda_logo.png',
  fengda_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/fengda_banner.jpg',
  fengda_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/fengda_tel_banner.png',

  guige_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/guige_logo.jpg',
  guige_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/guige_banner.jpg',
  guige_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/guige_tel_banner.png',

  juhuasuan_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/juhuasuan_logo.png',
  juhuasuan_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/juhuasuan_banner.jpg',
  juhuasuan_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/juhuasuan_tel_banner.jpg',

  rongyao_phone_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_phone_logo.jpg',
  rongyao_phone_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_phone_banner.jpg',
  rongyao_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_tel_banner.jpg',

  TMALL_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/TMALL_logo.png',
  TMALL_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/TMALL_banner.jpg',
  TMALL_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/TMALL_tel_banner.png',

  zirantang_logo: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zirantang_tel_banner.jpg',
  zirantang_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zirantang_banner.jpg',
  zirantang_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zirantang_tel_banner.jpg',

  ZH_bride_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/ZH_bride_banner.png',
  ZH_bride_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/ZH_bride_tel_banner.png',
  ZH_bride_1: require('../assets/images/case/ZH_bride_1.png').default,

  new_baojun_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/new_baojun_banner.png',
  new_baojun_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/new_baojun_tel_banner.png',
  new_baojun_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/new_baojun_1.png',
  new_baojun_2: require('../assets/images/case/new_baojun_2.png').default,

  zozo_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zozo_banner.png',
  zozo_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zozo_tel_banner.png',
  zozo_1: require('../assets/images/case/zozo_1.png').default,

  AFK_Arena_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/AFK_Arena_1.png',
  AFK_Arena_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/AFK_Arena_2.png',

  HZ_blue_qianbi: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/HZ_blue_qianbi.png',

  liantong_card: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/liantong_card.png',

  lagou: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/lagou.png',

  qidian_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/qidian_1.png',
  qidian_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/qidian_2.png',

  kfc_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/kfc_1.jpg',
  kfc_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/kfc_2.jpg',

  fengda_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/fengda_1.png',
  fengda_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/fengda_2.jpg',

  guige_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/guige_1.jpg',
  guige_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/guige_2.jpg',

  juhuasuan_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/juhuasuan_1.jpg',
  juhuasuan_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/juhuasuan_2.jpg',

  rongyao_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_1.jpg',
  rongyao_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_2.jpg',
  rongyao_3: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/rongyao_3.jpg',

  TMALL_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/TMALL_1.png',
  TMALL_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/TMALL_2.png',

  zirantang_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zirantang_1.jpg',
  zirantang_2: 'https://i0.hdslb.com/bfs/activity-plat/static/20200729/8a3e1fa14e30dc3be9c5324f604e5991/zirantang_2.jpg',

  SAN_GUO_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/-f7pQ8XMp.png',
  SAN_GUO_tel_banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/nZyxE~RF-X.png',
  SAN_GUO_1: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/W6RYJQAi5.png',
  SAN_GUO_2: 'https://activity.hdslb.com/blackboard/static/20200805/cffd107c73fc638c142399e72cb0cb0f/lAsI5o~To.gif'
}

export const HeaderOptions = {
  icon: headerIcon,
  tel: '400-622-3368',
  routerMap: [
    {
      name: '首页',
      path: '/',
      key: 'home',
    },
    {
      name: '营销产品',
      path: '/product.html',
      key: 'product',
    },
    {
      name: '动态观察',
      href: '//e.bilibili.com/main/observe',
      key: 'observe',
    },
    {
      name: '成功案例',
      path: '/case/',
      key: 'case',
    }
  ]
}

export const homeLinkList = [
  {
    name: '效果广告',
    path: 'https://cm.bilibili.com/ad/#/login'
  },
  {
    name: '品牌号',
    path: 'https://b.bilibili.com/'
  },
  {
    name: '花火',
    path: 'https://huahuo.bilibili.com'
  }
]

export const productNavList = [
  {
    type: '品牌营销',
    list: [
      {
        type: '展示型广告',
        id: 'product-nav-1'
      },
      {
        type: '整合营销解决方案',
        id: 'product-nav-2'
      }
    ]
  },
  {
    type: '效果营销',
    list: [
      {
        type: '流量场景',
        id: 'product-nav-3'
      },
      {
        type: '产品能力',
        id: 'product-nav-4'
      },
      {
        type: '投放目的',
        id: 'product-nav-5'
      }
    ]
  },
  {
    type: '内容营销',
    list: [
      {
        type: '焦点内容营销',
        id: 'product-nav-6'
      },
      {
        type: '大事件营销',
        id: 'product-nav-7'
      },
      {
        type: 'UP主营销',
        id: 'product-nav-8'
      }
    ]
  }
]

export const productList = [
  {
    type: '展示型广告',
    title: '展示型广告',
    content: [
      [
        {
          type: 'video',
          haveBg: true,
          gif: IMGS.gif_2_1_1,
          src: 'https://upos-sz-static.bilivideo.com/ssaxcode/1g/rs/m210510a230c9hvgxw0i4139i1lers1g-1-SiteTool_1080.mp4'
        },
        {
          type: 'text',
          title: '搜索品专',
          desc: '用户搜索品牌关键词时触发，展现在搜索结果第一位，强势传递品牌信息，抓取用户眼球；同时可与品牌号联动，引导粉丝沉淀'
        }
      ],
      [
        {
          type: 'text',
          title: '开屏',
          desc: '广告在APP打开时半屏/全屏展示，强视觉冲击<br/>强势锁定新一代的注意力',
        },
        {
          type: 'video',
          haveBg: true,
          gif: IMGS.gif_1_2,
          src: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/fmNtEIKbxu.MP4',
        }
      ],
      [
        {
          type: 'video',
          haveBg: true,
          gif: IMGS.gif_2_1,
          src: 'https://upos-sz-static.bilivideo.com/ssaxcode/m200628a21a4ihuh2yja3t1ttqvqjyai-1-SiteTool_1080.mp4'
        },
        {
          type: 'text',
          title: '大视窗',
          desc: '超大尺寸信息流 X 动态视频 = 品牌吸睛神器'
        }
      ],
      [
        {
          type: 'text',
          title: '联动霸屏',
          desc: '超强曝光原生触达，赋予品牌信息更大的传递空间<br/>开屏与首页焦点联动，长达20秒的创意承载时间，抓人眼球'
        },
        {
          type: 'video',
          gif: IMGS.gif_3_1,
          cls: 'bg-android',
          haveBg: true,
          src: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/LbeNgHYZgT.MP4'
        }
      ],
      [
        [
          {
            type: 'video',
            haveBg: true,
            gif: IMGS.bg_1_2_1,
            src: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/FOkbR73Q4P.MP4',
            cls: 'double active'
          },
          {
            type: 'picture',
            bg: IMGS.bg_1_2_2,
            cls: 'double'
          }
        ],
        {
          type: 'text',
          title: '焦点图',
          desc: '首页大流量覆盖<br/>25%屏幕占比，充分展现品牌信息',
          switchList: [
            {
              name: 'App',
              cls: 'active',
              bg: IMGS.bg_1_2_1,
              type: 'double-video'
            },
            {
              name: 'PC',
              bg: IMGS.bg_1_2_2,
              type: 'double-picture'
            }
          ]
        }
      ]
    ]
  },
  {
    type: '整合营销解决方案',
    title: '整合营销解决方案',
    content: [
      [
        {
          type: 'picture',
          bg: 'https://i0.hdslb.com/bfs/activity-plat/static/20210622/cffd107c73fc638c142399e72cb0cb0f/rL19HVmKqj.jpg'
        },
        {
          type: 'text',
          title: '进化论',
          links: [
            {
              name: '查看案例',
              link: '/case/lays.html'
              // link: '/case/detail.html?id=0'
            },
            {
              name: '解决方案',
              link: 'http://doc.baige.me/view/VvC'
            }
          ],
          desc: '串联话题生态，由 UP 主带队 PK 观点，持续引导话题发酵的整合营销新玩法；适用于产品上新、品牌焕新等营销场景。'
        }
      ],
      [
        {
          type: 'text',
          title: 'B站新品日',
          links: [
            {
              params: { id: 0 },
              name: '查看案例',
              link: '/case/rongyao.html'
              // link: '/case/detail.html?id=0'
            },
            {
              name: '解决方案',
              link: 'https://activity.hdslb.com/blackboard/static/20200730/8a3e1fa14e30dc3be9c5324f604e5991/OwIu0aXtW.pdf'
            }
          ],
          desc: 'B站新品营销解决方案，“直播+视频”两开花，聚焦新品卖点，强曝光吸睛，多互动炫酷，短周期内击穿用户认知，引爆品牌影响力'
        },
        {
          type: 'picture',
          bg: IMGS.bg_2_1
        }
      ],
      [
        {
          type: 'picture',
          bg: IMGS.bg_2_2
        },
        {
          type: 'text',
          title: 'B站召集令',
          links: [
            {
              params: { id: 2 },
              name: '查看案例',
              link: '/case/kfc.html'
              // link: '/case/detail.html?id=2'
            },
            {
              name: '解决方案',
              link: 'https://activity.hdslb.com/blackboard/static/20200730/8a3e1fa14e30dc3be9c5324f604e5991/BJ55G~slu.pdf'
            }
          ],
          desc: '通过视频内容加速扩散的话题营销解决方案<br/>以视频内容为触发点，聚合流量资源、UP主影响力和平台社区特性，一站式助力品牌快速完成“触达－聚拢－发酵－沉淀”的营销全链路'
        }
      ],
      [
        {
          type: 'text',
          title: '品牌号',
          desc: 'B站认证品牌官方账号，让品牌融入z世代'
        },
        {
          type: 'bg',
          bg: IMGS.bg_2_3
        }
      ]
    ]
  },
  {
    type: '流量场景',
    title: '流量场景',
    content: [
      [
        // {
        //   type: 'bg',
        //   bg: IMGS.bg_3_1_1
        // },
        [
          {
            type: 'video',
            haveBg: true,
            gif: IMGS.bg_3_1_1,
            src: 'https://activity.hdslb.com/blackboard/static/20211209/cffd107c73fc638c142399e72cb0cb0f/Yh5X4xWhOR.MP4',
            cls: 'double active'
          },
          {
            type: 'picture',
            bg: IMGS.bg_3_1_2,
            cls: 'double'
          }
        ],
        {
          type: 'text',
          title: '信息流',
          desc: '广告在APP打开时以原生形式出现在视频内容流场景，融入原生场景，结合用户兴趣呈现广告内容，支持站内外多种落地页形式，强势锁定新一代的注意力',
          switchList: [
            {
              name: '大卡',
              cls: 'active',
              bg: IMGS.bg_3_1_1,
              type: 'double-video'
            },
            {
              name: '小卡',
              bg: IMGS.bg_3_1_2,
              type: 'double-picture'
            }
          ]
        }
      ],
      [
        {
          type: 'text',
          title: '播放页推荐',
          desc: '融入播放页推荐视频，以原生形态自然呈现'
        },
        {
          type: 'bg',
          bg: IMGS.bg_3_2
        }
      ]
    ]
  },
  {
    type: '产品能力',
    title: '产品能力',
    content: [
      [
        {
          type: 'picture',
          bg: IMGS.bg_4_1
        },
        {
          type: 'text',
          title: '精准定向',
          links: [
            {
              params: { id: 7 },
              name: '查看案例',
              link: '/case/zozo.html'
              // link: '/case/detail.html?id=7'
            }
          ],
          desc: '常规的人群定向能力之外，结合B站内容兴趣大数据，支持多维度定向'
        }
      ],
      [
        {
          type: 'text',
          title: '智能投放',
          links: [
            {
              params: { id: 9 },
              name: '查看案例',
              link: '/case/zhaoshang.html'
              // link: '/case/detail.html?id=9'
            }
          ],
          desc: '根据效果转化智能调整出价，支持oCPC、oCPM'
        },
        {
          type: 'picture',
          bg: IMGS.bg_4_2
        }
      ],
      [
        {
          type: 'picture',
          bg: IMGS.bg_4_3
        },
        {
          type: 'text',
          title: '原生体验',
          links: [
            {
              params: { id: 8 },
              name: '查看案例',
              link: '/case/baojun.html'
              // link: '/case/detail.html?id=8'
            }
          ],
          desc: '效果落地页支持视频内容加转化组件，<br/>更贴近B站用户使用习惯'
        }
      ]
    ]
  },
  {
    type: '投放目的',
    title: '投放目的',
    content: [
      [
        {
          type: 'bg',
          bg: IMGS.bg_5_2
        },
        {
          type: 'text',
          title: '线索收集',
          desc: '支持表单填写和咨询互动，帮助客户进行客资收集'
        }
      ],
      [
        {
          type: 'text',
          title: '电商转化',
          desc: '支持DPA及电商平台唤起'
        },
        {
          type: 'bg',
          bg: IMGS.bg_5_3
        }
      ],
      [
        {
          type: 'bg',
          bg: IMGS.bg_5_4
        },
        {
          type: 'text',
          title: '游戏分发',
          desc: '支持游戏预约、激活、付费等投放模式'
        }
      ],
      [
        {
          type: 'text',
          title: '应用下载',
          desc: '支持呼起应用商店，或直接进行安装包下载'
        },
        {
          type: 'bg',
          bg: IMGS.bg_5_1
        }
      ]
    ]
  },
  {
    type: '焦点内容营销',
    title: '焦点内容营销',
    desc: '国创、纪录片、综艺、电竞等',
    content: [
      [
        {
          type: 'text',
          title: '内容植入',
          desc: '结合内容，无缝植入产品/品牌信息'
        },
        {
          type: 'picture',
          bg: IMGS.bg_6_1
        }
      ],
      [
        {
          type: 'video',
          gif: IMGS.gif_6_2,
          haveBg: false,
          src: 'https://upos-sz-static.bilivideo.com/ssaxcode/m200628a22iuib5lxmr66edlntf77a24-1-SiteTool_1080.mp4'
        },
        {
          type: 'text',
          title: '定制番外',
          desc: '为品牌定制创意番外'
        }
      ],
      [
        {
          type: 'text',
          title: 'IP授权',
          desc: 'IP授权上包装、 IP 授权周边、 IP 授权宣推'
        },
        {
          type: 'picture',
          bg: IMGS.bg_6_2
        }
      ],
      [
        {
          type: 'picture',
          bg: IMGS.bg_6_3
        },
        {
          type: 'text',
          title: '包装权益',
          desc: '标板、压屏条、片尾鸣谢等'
        }
      ]
    ]
  },
  {
    type: '大事件营销',
    title: '大事件营销',
    content: [
      [
        {
          type: 'text',
          title: 'bilibili新年晚会·最美的夜',
          desc: '“最美的夜”作为互联网视频行业首台新年晚会，播出后获全网热议“这场新年晚会很懂年轻人”'
        },
        {
          type: 'picture',
          bg: IMGS.bg_7_1
        }
      ],
      [
        {
          type: 'picture',
          bg: IMGS.bg_7_2
        },
        {
          type: 'text',
          title: 'BW (bilibili world)',
          desc: 'BW，每年举办的娱乐嘉年华，集演出、展览、互动游戏和周边售卖于一身，为喜爱B站内容的用户展现一个实体化世界'
        }
      ],
      [
        {
          type: 'text',
          title: 'BML (bilibili macro link)',
          desc: 'BML，目前中国规模最大的ACG线下演唱会之一。以B站核心内容为主题，集聚了一线动画歌手、人气UP主、网络年轻文化代表人物'
        },
        {
          type: 'picture',
          bg: IMGS.bg_7_3
        }
      ],
      [
        {
          type: 'picture',
          bg: IMGS.bg_7_4
        },
        {
          type: 'text',
          title: '拜年纪',
          desc: '拜年纪，属于B站用户自己的除夕夜线上盛会，体现着B站UP主在创作上的不懈追求与PUGV作品的质量高峰'
        }
      ]
    ]
  },
  {
    type: 'UP主营销',
    title: 'UP主营销',
    content: [
      [
        {
          type: 'text',
          title: '商单合作',
          desc: '品牌诉求与UP主内容自然结合，渗透传播，触达粉丝'
        },
        {
          type: 'bg',
          bg: IMGS.bg_8_1
        }
      ],
      [
        {
          type: 'bg',
          bg: IMGS.bg_8_2
        },
        {
          type: 'text',
          title: '创作推广',
          desc: '对UP主商单视频进行定向流量推广，助燃优质商单视频获取更多曝光'
        }
      ],
      [
        {
          type: 'text',
          title: '邀约广告',
          desc: '在UP主商单中增加强关联广告链接，实现后链路购买/下载转化闭环'
        },
        {
          type: 'bg',
          bg: IMGS.bg_8_3
        }
      ]
    ]
  }
]

export const caseSelectList = [
  {
    name: '行业',
    list: [
      {
        key: '',
        name: '全部'
      },
      {
        key: '电商',
        name: '电商'
      },
      {
        key: '美妆',
        name: '美妆'
      },
      {
        key: '快消',
        name: '快消'
      },
      {
        key: '3C数码',
        name: '3C数码'
      },
      {
        key: '游戏',
        name: '游戏'
      },
      {
        key: '教育',
        name: '教育'
      },
      {
        key: '运营商',
        name: '运营商'
      },
      {
        key: '网服',
        name: '网服'
      },
      {
        key: '汽车',
        name: '汽车'
      },
      {
        key: '金融',
        name: '金融'
      }
    ]
  }
]

export const adTalkSelectList = [
  {
    name: 'ADTalk案例赏析',
    list: [
      {
        key: '',
        name: '全部'
      },
      {
        key: '2020年度十大真香',
        name: '2020年度十大真香'
      }
    ]
  }
]

export const caseProductList = [
  {
    title: '自然堂',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/hgDzS67YK5.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/OtcVIAmds.jpg',
    desc: '自然堂 x bilibili小电视联名彩妆跨界营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      'IP联名',
      '大事件营销'
    ],
    content: [
      {
        name: '彩妆品类整体销量增长',
        num: '80%+'
      },
      {
        name: '联名产品双十二期间销量',
        num: '18万',
        unit: '份+'
      }
    ]
  },
  {
    title: '纽西之谜',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/kuKdv9o1Vr.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/P3frSCDqx.png',
    desc: '纽西之谜IP联名营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      'B站召集令',
      '焦点内容'
    ],
    content: [
      {
        name: '话题浏览量',
        num: '640',
        unit: '万+'
      },
      {
        name: 'B站热门话题连续上榜',
        num: '12',
        unit: '天'
      }
    ]
  },
  {
    title: '蒙牛早餐奶',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/-ljfGmk5uD.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/k8wrGrYhd.png',
    desc: '蒙牛早餐奶 x 《说唱新世代》综艺营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '焦点内容'
    ],
    content: [
      {
        name: '节目总播放量',
        num: '4.5',
        unit: '亿+'
      }
    ]
  },
  {
    title: 'Jordan',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/zsQgHgGyhb.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/fWJjytuUZ.jpg',
    desc: "JORDAN ZOOM '92 新品上市魂穿90直播派对",
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '直播活动',
      '展示型广告'
    ],
    content: [
      {
        name: '直播人气峰值',
        num: '330',
        unit: '万+'
      },
      {
        name: '总曝光量',
        num: '1.5',
        unit: '亿+'
      }
    ]
  },
  {
    title: 'iQOO',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/lvpYFdoYFn.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/0GKUFYvFG.jpg',
    tipImg: IMGS.rongyao_phone_logo,
    desc: 'iQOO快把电给我满上超能10小时系列直播',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '直播活动',
      '展示型广告'
    ],
    content: [
      {
        name: '最高人气峰值',
        num: '709',
        unit: '万'
      },
      {
        name: '话题总浏览',
        num: '1.85',
        unit: '亿'
      }
    ]
  },
  {
    title: '雷克萨斯',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/zgNBDdQZzv.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/~PhN3w5Ra.png',
    desc: '雷克萨斯 x B站人生电影《任务》新营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      'B站召集令'
    ],
    content: [
      {
        name: '话题浏览量',
        num: '3642.4',
        unit: '万'
      },
      {
        name: '共创作品',
        num: '1000+'
      }
    ]
  },
  {
    title: '聚划算',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/K16rQytAKN.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/CMUTGFnXM.jpg',
    desc: '聚划算 x B站焦点内容整合营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '焦点内容'
    ],
    content: [
      {
        name: '跨年晚会播放量',
        num: '1',
        unit: '亿+'
      },
      {
        name: '《说唱新世代》播放量',
        num: '3.6',
        unit: '亿'
      }
    ]
  },
  {
    title: '一加',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/V~voXqfBRf.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/sfinCGYR6.jpg',
    desc: '一加8系列 新机发布整合营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '数码前线计划',
      '焦点内容'
    ],
    content: [
      {
        name: '总曝光量',
        num: '11',
        unit: '亿+'
      },
      {
        name: '品牌号涨粉',
        num: '18',
        unit: '万+'
      }
    ]
  },
  {
    title: '建设银行',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/fhNQ7yJpvf.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/cplZa7lmK.png',
    desc: '建行信用卡 x bilibili小电视 品牌年轻化营销',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      'B站召集令',
      'IP联名'
    ],
    content: [
      {
        name: '建行×bilibili 金融小站全国总数',
        num: '100',
        unit: '家+'
      },
      {
        name: '开学季活动曝光量',
        num: '2',
        unit: '亿'
      }
    ]
  },
  {
    title: 'ZOZO',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/VTQk6taiWS.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/TF3KPk0eF.jpg',
    desc: 'ZOZO玩转UP主内容种草激活效果新模式',
    key: '2020年度十大真香',
    tags: [
      '年度十大真香',
      '信息流',
      'UP主营销'
    ],
    content: [
      {
        name: '下载成本',
        num: '-60%'
      }
    ]
  },
  {
    title: '荣耀',
    banner: IMGS.rongyao_phone_banner,
    telBG: IMGS.rongyao_tel_banner,
    tipImg: IMGS.rongyao_phone_logo,
    desc: '借力B站新品日，推广新机上市',
    key: '3C数码',
    tags: [
      'B站新品日',
      'UP主营销'
    ],
    content: [
      {
        name: '直播人气',
        num: '700',
        unit: '万+'
      },
      {
        name: 'B站热搜榜',
        num: 'No.3',
        unit: '位'
      }
    ]
  },
  {
    banner: IMGS.fengda_banner,
    telBG: IMGS.fengda_tel_banner,
    tipImg: IMGS.fengda_logo,
    title: '芬达',
    desc: '芬达 x bilibili脑洞节，与年轻人玩在一起',
    key: '快消',
    tags: [
      'UP主营销'
    ],
    content: [
      {
        name: '曝光量',
        num: '1.9',
        unit: '亿'
      },
      {
        name: '总播放量',
        num: '1200',
        unit: '万'
      }
    ]
  },
  {
    banner: IMGS.kfc_banner,
    telBG: IMGS.kfc_tel_banner,
    tipImg: IMGS.kfc_logo,
    title: 'KFC',
    desc: '借B站召集令，KFC全民复刻原味鸡',
    key: '快消',
    tags: [
      'B站召集令',
      'UP主营销'
    ],
    content: [
      {
        name: 'B站热门话题榜',
        num: 'No.1',
        unit: '位'
      },
      {
        name: '热门话题榜上榜',
        num: '12',
        unit: '天'
      }
    ]
  },
  {
    banner: IMGS.guige_banner,
    telBG: IMGS.guige_tel_banner,
    tipImg: IMGS.guige_logo,
    title: '桂格',
    desc: '《请吃红小豆吧！第三季》，打造“追番神器”',
    key: '快消',
    tags: [
      '焦点内容',
      'UP主营销'
    ],
    content: [
      {
        name: '曝光量',
        num: '2.4',
        unit: '亿+'
      },
      {
        name: '总点击量',
        num: '200',
        unit: '万+'
      }
    ]
  },
  {
    banner: IMGS.juhuasuan_banner,
    telBG: IMGS.juhuasuan_tel_banner,
    tipImg: IMGS.juhuasuan_logo,
    title: '聚划算',
    desc: '冠名bilibili晚会，打造年轻人的跨年盛典',
    key: '电商',
    tags: [
      '焦点内容'
    ],
    content: [
      {
        name: '播放量',
        num: '1',
        unit: '亿'
      },
      {
        name: '全网曝光',
        num: '50',
        unit: '亿'
      }
    ]
  },
  {
    title: '天猫',
    banner: IMGS.TMALL_banner,
    telBG: IMGS.TMALL_tel_banner,
    tipImg: IMGS.TMALL_logo,
    desc: 'B站双11种草大会，助力双11营销',
    key: '电商',
    tags: [
      'UP主营销'
    ],
    content: [
      {
        name: '种草视频',
        num: '20000',
        unit: '+'
      },
      {
        name: '播放量',
        num: '1.6',
        unit: '亿'
      }
    ]
  },
  {
    title: '自然堂',
    banner: IMGS.zirantang_banner,
    telBG: IMGS.zirantang_tel_banner,
    tipImg: IMGS.zirantang_logo,
    desc: 'B站联名彩妆，年轻族群心头爱',
    key: '美妆',
    tags: [
      '焦点内容',
      'UP主营销'
    ],
    content: [
      {
        name: '销售量',
        num: '10',
        unit: '万+'
      },
      {
        name: '第一批售罄',
        num: '10',
        unit: '分钟'
      }
    ]
  },
  {
    banner: IMGS.zozo_banner,
    telBG: IMGS.zozo_tel_banner,
    title: 'ZOZO',
    desc: '精准定向×UP主内容，高效拉新',
    key: '电商',
    tags: [
      '信息流',
      'UP主营销'
    ],
    content: [
      {
        name: '转化成本较纯信息流广告降低',
        num: '60%'
      }
    ]
  },
  {
    banner: IMGS.new_baojun_banner,
    telBG: IMGS.new_baojun_tel_banner,
    title: '新宝骏',
    desc: 'UP主内容智造，多圈层玩转新车',
    key: '汽车',
    tags: [
      '信息流',
      'UP主营销'
    ],
    content: [
      {
        name: '留资成本',
        num: '-20%'
      },
      {
        name: 'CTR高于站内平均',
        num: '148%'
      }
    ]
  },
  {

    banner: IMGS.ZH_bride_banner,
    telBG: IMGS.ZH_bride_tel_banner,
    title: '招商银行',
    desc: '2233花嫁IP联名，命中用户尖叫点',
    key: '金融',
    tags: [
      '信息流',
      'IP联合'
    ],
    content: [
      {
        name: 'CTR高于站内平均',
        num: '57%'
      },
      {
        name: '办卡成本',
        num: '-30%'
      }
    ]
  },
  {
    banner: IMGS.SAN_GUO_banner,
    telBG: IMGS.SAN_GUO_tel_banner,
    title: '放开那三国',
    desc: '全链路多阶段持续触达潜在玩家',
    key: '游戏',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: '日均注册用户数',
        num: '1000+'
      },
      {
        name: '用户付费率',
        num: '10%+'
      }
    ]
  },
  {
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/X7s0BLLOxH.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/2D3M5i69H5.png',
    title: '联通',
    desc: '紧扣B站IP，玩转原生福利',
    key: '运营商',
    tags: [
      '信息流'
    ],
    content: [
      {
        name: '日申领量',
        num: '4000+'
      },
      {
        name: '领卡转化率',
        num: '56%'
      }
    ]
  },
  {
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/TmCWmcpk1M.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/UCW8e8Mr8b.png',
    title: '潭州教育',
    desc: '用趣味方式解锁效果广告',
    key: '教育',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: '日线索量',
        num: '50000+'
      },
      {
        name: '表单成本低于行业平均',
        num: '20%'
      }
    ]
  },
  {
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/1mIhLkHozx.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/BcONSkhjJt.png',
    title: '知乎',
    desc: '海量内容素材，直达用户内心',
    key: '网服',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: 'CPA下降',
        num: '15%'
      },
      {
        name: '留存质量综合',
        num: '9',
        unit: '分'
      }
    ]
  },
  {
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/n9VfinoENW.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/xDTXwDTZ0G.png',
    title: '自然堂',
    desc: '精准内容，精准分发，精准种草',
    key: '美妆',
    tags: [
      '信息流',
      'UP主营销'
    ],
    content: [
      {
        name: 'CTR高于同行业平均水平',
        num: '61%'
      },
      {
        name: '合作UP主',
        num: '31',
        unit: '位'
      }
    ]
  },
  {
    banner: IMGS.HZ_blue_qb_banner,
    telBG: IMGS.HZ_qb_tel_banner,
    tipImg: IMGS.HZ_blue_qb_logo,
    title: '杭州蓝铅笔',
    desc: '运用兴趣关键词，全方位覆盖用户兴趣点',
    key: '教育',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: '表单成本',
        num: '-25%'
      }
    ]
  },
  {
    title: '联通卡',
    banner: IMGS.liantong_banner,
    telBG: IMGS.liantong_tel_banner,
    tipImg: IMGS.liantong_logo,
    desc: '联动B站IP，高效引流高效转化',
    key: '运营商',
    tags: [
      '信息流'
    ],
    content: [
      {
        name: 'CTR整体优化比例',
        num: '40%',
        unit: '+'
      },
      {
        name: '成本优化',
        num: '15%'
      }
    ]
  },
  {
    title: '拉勾',
    banner: IMGS.lagou_banner,
    telBG: IMGS.lagou_tel_banner,
    tipImg: IMGS.lagou_logo,
    desc: '深度合作，结合B站元素投放',
    key: '网服',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: '点击率高于网服行业平均水平',
        num: '60%'
      },
      {
        name: '转化率高于网服行业平均水平',
        num: '85%'
      }
    ]
  },
  {
    banner: IMGS.AFK_Arena_banner,
    telBG: IMGS.AFK_tel_banner,
    tipImg: IMGS.AFK_Arena_logo,
    title: '剑与远征',
    desc: '多类型创意投放，精准锁定用户',
    key: '游戏',
    tags: [
      '信息流',
      '播放页相关推荐'
    ],
    content: [
      {
        name: '7日激活数同比增加',
        num: '80%'
      }
    ]
  },
  {
    title: '起点阅读',
    banner: IMGS.qidian_banner,
    telBG: IMGS.qidian_tel_banner,
    tipImg: IMGS.qidian_logo,
    desc: '巧借oCPX能力优化成本，促成优质转化',
    key: '网服',
    tags: [
      '信息流',
      '播放页相关推荐',
      '创作推广'
    ],
    content: [
      {
        name: '安卓成本相比客户考核成本降低',
        num: '40%'
      }
    ]
  }
].map((item, index) => ({ ...item, id: index }))

export const caseDetailList = [
  {
    banner: {
      title: '自然堂 x bilibili小电视联名彩妆跨界营销',
      name: '自然堂',
      data: [
        {
          name: '彩妆品类整体销量增长',
          num: '80%+'
        },
        {
          name: '联名产品双十二期间销量',
          num: '18万',
          unit: '份+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '自然堂于2019年推出面向Z世代年轻人的全新彩妆产品，希望在年底电商大促期间吸引年轻人，提升产品销量。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1. 匠心设计将IP与产品深度结合',
          '2. BW线下彩妆工厂打造冬日打卡热门目的地'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、IP联名款吸睛增加好感',
          '2、UP主种草加速购买决策',
          '3、BW线下互动增强情感联系'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/PJn3Sjoilh.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/BazpjaUico.jpg'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '彩妆品类整体销量增长80%+',
          '联名产品双十二期间售出18万+份'
        ]
      }
    ]
  },
  {
    banner: {
      title: '纽西之谜IP联名营销',
      name: '纽西之谜',
      data: [
        {
          name: '话题浏览量',
          num: '640',
          unit: '万+'
        },
        {
          name: 'B站热门话题连续上榜',
          num: '12',
          unit: '天'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '纽西之谜合作B站融入年轻圈层收获年轻用户好感，并配合双十一双十二电商购物节点，促成电商转化。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、以「心水」天官为主题合作IP内容，将产品卖点融入到IP与粉丝的情感连接中',
          '2、以「心水」天官为主题召集用户表白，通过用户互动进一步引爆IP联名影响力'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、打造入站、心水、热爱、拔草的营销节奏形成营销闭环，融入年轻用户兴趣圈层',
          '2、以B站年度人气国创上线+电商节点营销黄金节点，深度合作IP'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/9G2vzCkHg1.jpg'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '话题浏览量超640万',
          'B站热门话题连续上榜12天'
        ]
      }
    ]
  },
  {
    banner: {
      title: '蒙牛早餐奶 x 《说唱新世代》综艺营销',
      name: '蒙牛早餐奶',
      data: [
        {
          name: '节目总播放量',
          num: '4.5',
          unit: '亿+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '花色奶行业新品频出竞争激烈，面对年轻一代消费者，蒙牛早餐奶希望通过与B站的合作助力品牌焕新，沟通年轻一代心智。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '蒙牛早餐奶作为《说唱新世代》首席合作伙伴，抓住品牌与说唱“活力满满”的共通点，首次试水B站综艺即获数亿播放，“keep蒙牛早餐奶，开口就有punchline”年轻有态度的营养美味早餐奶收获新世代rapper偏爱奶不离手；牛蒙蒙激情互动，夯实潮流时尚“牛设”，收获年轻观众认同。'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '占位B站超S级年轻文化娱乐内容，深度沉浸式合作'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/acWppee8G6.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/xyCSCRhS8B.png'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '节目总播放量超4.5亿'
        ]
      }
    ]
  },
  {
    banner: {
      title: "JORDAN ZOOM '92 新品上市魂穿90直播派对",
      name: 'Jordan',
      data: [
        {
          name: '直播人气峰值',
          num: '330',
          unit: '万+'
        },
        {
          name: '总曝光量',
          num: '1.5',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          'Nike Jordan新款鞋履致敬92年的经典设计，表达新的潮流主张，希望通过与B站合作在年轻目标消费者群体中打响新品上市第一枪。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、发布会变身魂穿90潮流派对主题',
          '2、UP主与明星嘉宾参与派对，助力发布会人气',
          '3、发布会环节以微综艺形式呈现，自然趣味地传递新品卖点'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、发布会以派对直播形式呈现，以内容包围新品，提升用户关注度与互动性',
          '2、人气UP主加持，将品牌明星与B站用户巧妙连接'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/szliRI7jgs.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/qZVOgrQC45.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/2JeGXSXB1~.png'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '直播人气峰值330万+',
          '总曝光量1.5亿'
        ]
      }
    ]
  },
  {
    banner: {
      title: 'iQOO快把电给我满上超能10小时系列直播',
      name: 'iQOO',
      data: [
        {
          name: '最高人气峰值',
          num: '709',
          unit: '万'
        },
        {
          name: '话题总浏览量',
          num: '1.85',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '疫情期间，需要以线上发布会的形式引爆产品发布的声量，最大化产品关注度。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、以内容包围产品，将iQOO的发布会以及产品卖点成为直播内容的一部分',
          '2、相同模式，升级玩法，连续两季打造，让【10小时超能直播】形成iQOO在B站的独有营销IP'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、内容上：通过用户喜欢的UP主及内容，围绕新机卖点打造直播内容；',
          '2、形式上：10小时超长直播+超豪华抽奖福利，形成全站发酵大事件',
          '3、传播上：整合优质传播资源，迅速引爆iQOO新品上市声量'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/2Fdnt3LvC-.jpg']
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/HX-xzilQDm.jpg']
      },
      {
        title: '合作效果',
        desc: [
          '第一季直播人气峰值623万',
          '第二季直播人气峰值709万',
          '第一季话题浏览量9098.1万',
          '第二季话题浏览量9428.8万'
        ]
      }
    ]
  },
  {
    banner: {
      title: '雷克萨斯 x B站人生电影《任务》新营销',
      name: '雷克萨斯',
      data: [
        {
          name: '话题浏览量',
          num: '3642.4',
          unit: '万'
        },
        {
          name: '共创作品',
          num: '1000+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '雷克萨斯于7月底上线由王俊凯主演的人生电影「任务」，希望引导更多年轻用户观看，传递“爱是科技的归宿”品牌理念。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、多圈层：跳脱围绕核心圈层的固有思维，多圈层UP主共同为品牌发力',
          '2、双玩法：动态扩散+内容定制，双重玩法助力品牌内容',
          '3、重内容：围绕不同阶段内容诉求，定制脑洞内容持续影响力'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '品牌发起#王俊凯变身机器人#召集令活动释放多效牵引力，进阶解锁用户认同。'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/b5cnqZHtj5.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/8ajJEX9kkd.png'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '话题浏览量3642.4万',
          '共创作品1000+'
        ]
      }
    ]
  },
  {
    banner: {
      title: '聚划算 x B站焦点内容整合营销',
      name: '聚划算',
      data: [
        {
          name: '跨年晚会播放量',
          num: '1',
          unit: '亿+'
        },
        {
          name: '《说唱新世代》播放量',
          num: '3.6',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '聚划算借力B站优质内容，融入年轻人文化社区，高效触达Z世代用户。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、亮相跨年晚会，以“百亿补贴”强化用户记忆',
          '2、融入《说唱新世代》节目生态，把品牌内容玩成刷屏爆梗'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '聚划算持续合作B站优质IP内容，紧跟节目热点，满足用户需求，造梗玩梗，与用户同好“干杯”。'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/LZ6L66odZ4.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/KPBHBIMYTW.png'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '跨年晚会播放量1亿+',
          '《说唱新世代》播放量3.6亿'
        ]
      }
    ]
  },
  {
    banner: {
      title: '一加8系列 新机发布整合营销',
      name: '一加',
      data: [
        {
          name: '总曝光量',
          num: '11',
          unit: '亿+'
        },
        {
          name: '品牌号涨粉',
          num: '18',
          unit: '万+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '一加8系列主打120Hz高帧屏幕卖点，需要通过多方位内容传播提升用户对一加8系列“高清屏幕”的认知，助推新机上市。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、一加独家尝鲜联动霸屏产品，树立行业合作标杆',
          '2、打造业内首支120Hz国创PV，吸引用户关注',
          '3、人气UP主创作优质内容，加深用户对一加120Hz产品卖点认知'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、UP主从不同角度讲述“高帧屏幕”，强化产品卖点',
          '2、明星跨界，带动用户关注',
          '3、绑定国创IP，吸引多圈层用户喜爱'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/qLLII3whpm.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/ICZqJ194iD.png'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '总曝光量11亿+',
          '一加品牌号涨粉18万+'
        ]
      }
    ]
  },
  {
    banner: {
      title: '建行信用卡 x bilibili小电视 品牌年轻化营销',
      name: '中国建设银行',
      data: [
        {
          name: '建行×bilibili 金融小站全国总数',
          num: '100',
          unit: '家+'
        },
        {
          name: '开学季活动曝光量',
          num: '2',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '中国建设银行希望携手年轻人喜爱的B站，提升年轻用户对于建行信用卡的认知与好感，吸引更多年轻人打卡建行。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、用Z世代喜爱的B站小电视IP，定制联名信用卡',
          '2、开学季，在全国打造百家联名金融小站，联合B站召集令掀起话题讨论',
          '3、优势头部UP主内容共创，内容好感度转化为品牌亲近度'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1、借势人气IP，品牌形象焕新',
          '2、趁势开学节点，热度加持话题度',
          '3、合作UP主共创，优质内容加持品牌好感'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/77ZDf54DBE.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/zqmL4HwBhq.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/DmwBhSA~IL.jpg'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '建行×bilibili 金融小站全国超100家',
          '开学季活动曝光量2亿'
        ]
      }
    ]
  },
  {
    banner: {
      title: 'ZOZO玩转UP主内容种草激活效果新模式',
      name: 'ZOZO',
      data: [
        {
          name: '下载成本',
          num: '-60%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '日本著名时尚购物平台ZOZO进入中国，希望吸引更多年轻新用户关注。'
        ]
      },
      {
        title: '创意亮点',
        desc: [
          '1、通过UP主内容种草不断获取新用户并沉淀目标用户群',
          '2、视频化原生素材，提升转化效果'
        ]
      },
      {
        src: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/aQQNdJvLyK.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/cybM8D0-6.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201215/cffd107c73fc638c142399e72cb0cb0f/19Z43uVYWm.jpg'
        ]
      },
      {
        title: '策略分析',
        desc: [
          '1. 搭建UP主内容矩阵',
          '2. 视频化创意素材+精准定向'
        ]
      },
      {
        title: '合作效果',
        desc: [
          '下载成本降低60%'
        ]
      }
    ]
  },
  {
    banner: {
      title: '借力B站新品日，荣耀推广新机上市',
      name: '荣耀',
      data: [
        {
          name: '直播人气',
          num: '700',
          unit: '万+'
        },
        {
          name: 'B站热搜榜',
          num: 'No.3',
          unit: '位'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '荣耀即将在2020年4月15日发布旗舰机型荣耀30系列，受疫情影响，会选择以直播的形式举办新机发布会。荣耀希望能够与备受年轻人喜爱的B站合作，将产品的核心卖点“50倍变焦超级稳”传递到年轻TA中，拉近与年轻TA距离。'
        ]
      },
      {
        src: [IMGS.rongyao_1]
      },
      {
        title: '营销策略及落地',
        desc: [
          '内容上突破以往直播模式，打造“直播plus”模式。',
          '产品上通过B站直播新产品——B站新品日，调动更多组合资源推广此次荣耀30新品发布会直播。',
          '荣耀30主打年轻群体，B站作为年轻浓度极高的平台，利用直播这一年轻人喜爱的形式、vlog这一年轻人记录生活的方式，打入生活化场景，放大荣耀30核心卖点在生活中应用，拉近与年轻群体的距离。'
        ]
      },
      {
        src: [IMGS.rongyao_2]
      },
      {
        title: '合作效果',
        desc: [
          'B站直播人气值700万，超出荣耀30其他家媒体直播人气3倍多。',
          '自然流量登上全站当日热搜第三。',
          '荣耀B站官号直播人气，高出业内同期直播人气3倍。',
          '活动期间荣耀官号涨粉近20万。'
        ]
      },
      {
        src: [IMGS.rongyao_3]
      }
    ]
  },
  {
    banner: {
      title: '芬达 x bilibili脑洞节，与年轻人玩在一起',
      name: '芬达',
      data: [
        {
          name: '曝光量',
          num: '1.9',
          unit: '亿'
        },
        {
          name: '总播放量',
          num: '1200',
          unit: '万'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '芬达一直在年轻人中传递“有趣好玩”的品牌形象。此次和B站合作，也希望能和B站年轻人玩在一起，用B站方式演绎芬达品牌调性。'
        ]
      },
      {
        src: [IMGS.fengda_1]
      },
      {
        title: '营销策略及落地',
        desc: [
          '融入B站生态，以B站运营活动脑洞节为切入点，号召全站用户打开脑洞，秀出自己的神操作，在有趣好玩的共创氛围中输出和巩固芬达好玩人设。',
          '【自上而下大曝光】主站运营IP（脑洞节），全站曝光触达目标人群。',
          '【自下而上深共创】面向全站用户征集神操作；开发扭蛋机玩法，降低用户参与门槛，扩大参与范围；用户集赞可兑换脑洞节头像框，社交裂变引发更多关注和参与。',
          '【共振矩阵强包围】合作多分区UP主为芬达定制视频多圈层渗透，芬达官号与合作UP主联合投稿，帮助官号完成内容沉淀。'
        ]
      },
      {
        src: [IMGS.fengda_2]
      },
      {
        title: '合作效果',
        desc: [
          '【脑洞节】页面PV4500万+，投稿7000+，扭蛋机参与互动超23万，相关动态话题浏览超7300万。',
          '【UP主】芬达相关视频总播放近1200万，互动率达15%'
        ]
      },
      {
        title: '合作参考链接',
        link: 'https://www.bilibili.com/blackboard/activity-EXxEjKYRG.html'
      }
    ]
  },
  {
    banner: {
      title: '借B站召集令，KFC全民复刻原味鸡',
      name: 'KFC',
      data: [
        {
          name: 'B站热门话题榜',
          num: 'No.1',
          unit: '位'
        },
        {
          name: '热门话题榜上榜',
          num: '12',
          unit: '天'
        }
      ],
      imgs: [
        IMGS.banner1,
        IMGS.banner2
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '肯德基迎来吮指原味鸡80周年，希望趁节点打造品牌大事件，全面渗透年轻圈层引发群体共鸣，与用户深度沟通炸鸡文化。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          'B站召集令赋予肯德基三大品牌营销力，打造现象级大事件实现品牌资产升舱。',
          '品牌内容矩阵：爆款UP主内容出圈感召，刷屏式动态撑起话题热度。',
          '品牌活动阵地：超能话题页，一个话题，来去自如，击破内容与社交壁垒，拉低用户参与门槛，互联互通高效引流。',
          'UGC话题发酵：全民级话题讨论裂变，用户主动反哺品牌内容。'
        ]
      },
      {
        src: [IMGS.kfc_1, IMGS.kfc_2]
      },
      {
        title: '合作效果',
        desc: [
          '召集令优质资源点位凸显大事件曝光吸睛力：B站热门话题榜第1名，话题榜上榜时间12天。',
          'B站召集令硬核实力成就站内现象级话题事件，帮助KFC品牌号涨粉20%'
        ]
      }
    ]
  },
  {
    banner: {
      title: '桂格 x《请吃红小豆吧！第三季》，树“追番神器”形象',
      name: '桂格',
      data: [
        {
          name: '曝光量',
          num: '2.4',
          unit: '亿+'
        },
        {
          name: '总点击量',
          num: '200',
          unit: '万+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '桂格新品大果粒乳酸菌麦片上市，新加果干、酸奶块，主打年轻女性消费者，推广“美味有料”的产品卖点。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          '桂格合作《请吃红小豆吧！第三季》定制角色打造爆款番外，树立『追番神器』形象传递“美味 有料”，上手B站营销玩法打破年轻沟通壁垒。',
          '【爆款打造】绑定国创大热IP打造爆款番外，品牌角色定制俘获年轻心智。',
          '【热度延伸】UP主搭载IP热潮，多圈层铺开覆盖更多TA。',
          '【流量背书】商业产品借助IP视觉，精准导流构建流量背书。'
        ]
      },
      {
        src: [IMGS.guige_1]
      },
      {
        title: '合作效果',
        desc: [
          '【硬广】总PV2.4亿+，总点击200万+',
          '【多圈层UP主】邀请4大圈层11位UP主，总播放量达1300w+，总互动量达16w+'
        ]
      },
      {
        src: [IMGS.guige_2]
      },
      {
        title: '合作参考链接',
        link: 'https://www.bilibili.com/bangumi/play/ep318868'
      }
    ]
  },
  {
    banner: {
      title: '聚划算冠名bilibili晚会，打造年轻人喜爱的跨年盛典',
      name: '聚划算',
      data: [
        {
          name: '播放量',
          num: '0.9',
          unit: '亿'
        },
        {
          name: '全网曝光',
          num: '50',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '聚划算品牌再次出发，希望在跨年节点下抓住年轻群体的注意力，抢占百亿补贴的认知高地，将百亿补贴的营销内核传递给他们，营造一个欢聚消费盛典。'
        ]
      },
      {
        src: [IMGS.juhuasuan_1]
      },
      {
        title: '营销策略及落地',
        desc: [
          '生态化场景营销：流量+内容+创意。',
          '【流量大】撬动站内外多类资源，抢占TA视野。',
          '【内容佳】一场以年轻人审美为核心的bilibili晚会。',
          '【创意秀】以用户互动、品牌联动及定制创意等方式呈现独特的联合宣推效果。'
        ]
      },
      {
        src: [IMGS.juhuasuan_2]
      },
      {
        title: '合作效果',
        desc: [
          '聚划算借助bilibili新年晚会，占领年轻用户心智',
          'bilibili晚会的优质内容获得B站播放量达1亿，全网曝光超50亿，冠名商身份全程展现，并为聚划算在长尾期带来营销裂变。',
          '超出圈：微博自然热搜12个，话题阅读量超25亿。',
          '好口碑：全网高分，B站9.9分，豆瓣9.1分，知乎9.3分。'
        ]
      },
      {
        title: '合作参考链接',
        link: 'https://www.bilibili.com/blackboard/topic/2019.html'
      }
    ]
  },
  {
    banner: {
      title: '天猫 x B站双11种草大会，助力双11营销',
      name: '天猫',
      data: [
        {
          name: '种草视频',
          num: '20000',
          unit: '+'
        },
        {
          name: '播放量',
          num: '1.6',
          unit: '亿'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '临近双11，爆款清单频出，让人眼花缭乱，但值不值得买是每位消费者面临的大难题。',
          '在此期间，B站用户也纷纷摩拳擦掌想要分享购物经验，对于天猫而言，这是机遇，也是挑战，如何最大化利用这份天时地利，为双11营销助力成为天猫2019年双11营销关键。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          '天猫携手B站双11种草大会活动IP，产出精品种草内容，营造全平台剁手氛围。',
          '【前期】开启「双11产品体验官」，邀请用户领取小样，发布真实体验；50家品牌&79个产品参与试用活动。',
          '【后期】评选「双11种草榜单」，引导用户分享全方位剁手心得，种草导流；渗透各圈层，包揽购物前后全链路。'
        ]
      },
      {
        src: [IMGS.TMALL_1]
      },
      {
        title: '合作效果',
        desc: [
          '双11种草大会曝光和触达量高达千万级别，产品体验官超万人报名，撬动4500+位用户投稿优质原创内容。',
          '种草榜单征稿20000+支视频并收获1.6亿播放，穿透UP主、KOC、围观群众等各个层级，击破时尚、数码、美食等各个圈层。'
        ]
      },
      {
        src: [IMGS.TMALL_2]
      },
      {
        title: '合作参考链接',
        link: 'https://www.bilibili.com/blackboard/activity-Pep6Kqe_6.html?'
      }
    ]
  },
  {
    banner: {
      title: '自然堂 x B站联名彩妆，打造年轻人心头爱',
      name: '自然堂',
      data: [
        {
          name: '销售量',
          num: '10',
          unit: '万+'
        },
        {
          name: '第一批售罄',
          num: '10',
          unit: '分钟'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '自然堂于2019年推出面向Z世代年轻人的全新彩妆产品，希望在年底电商大促期间迅速吸引年轻人，提升产品销量。'
        ]
      },
      {
        src: [IMGS.zirantang_1]
      },
      {
        title: '营销策略及落地',
        desc: [
          '携手Z世代喜爱的IP共创产品，并在年底的线下嘉年华引爆线上购买激情。',
          '【线上】IP联名款释放尖叫。授权B站用户喜爱的小电视形象，并由B站参与产品设计，与自然堂共创一款让Z世代萌到尖叫的彩妆产品，通过软萌颜值、硬核品质的完美结合，吸引用户种草购买。',
          '【线下】嘉年华现场种草。合作12月21-22日在成都举办的BW(bilibili world)活动，将产品设计概念在线下复刻呈现给用户，通过深度体验与互动，线下往线上电商购买导流的同时，加深用户好感。'
        ]
      },
      {
        src: [IMGS.zirantang_2]
      },
      {
        title: '合作效果',
        desc: [
          '【硬广】 总曝光量4亿+，总点击350万+。',
          '【BW】展台访问：4万+。',
          '与B站的合作，切实带动了自然堂彩妆产品在12月的销量。联名唇膏售出10万+份，联名气垫售出8万+份；第一批10分钟售罄，双十二销量同比增长8倍。'
        ]
      }
    ]
  },
  {
    banner: {
      title: 'UP主内容×精准定向，高效拉新',
      name: 'ZOZO',
      data: [
        {
          name: '转化成本较纯信息流广告降低',
          num: '60%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '日本著名时尚购物平台ZOZO进入中国，首选B站作为投放阵地，以获取年轻新用户。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          'UP优质内容×精准人群定向，高效助力ZOZO完成新用户获取。',
          '1. 搭建UP主内容矩阵，创作推广助力ZOZO撒网捕捉优质流量用户群。',
          '2. UP主形象授权配合原生活动，为ZOZO持续导流。',
          '3. 创意素材组+精准人群包复投，完成ZOZO用户拉新任务。'
        ]
      },
      {
        src: [IMGS.zozo_1] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          '转化成本较之前纯信息流广告投放降低了60%，且转化量显著提升，同时通过专题页活动极大的拉动了用户的参与度，达到了成本、量级和口碑的三赢。'
        ]
      }
    ]
  },
  {
    banner: {
      title: 'UP主内容智造，多圈层玩转新车',
      name: '新宝骏',
      data: [
        {
          name: '留资成本',
          num: '-20%'
        },
        {
          name: 'CTR高于站内平均',
          num: '148%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '新宝骏E300/E300Plus新车上市，希望与B站年轻用户一起玩转新车，为线上发布会导流和留资。。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          '不同圈层领域UP主内容智造，带领年轻人玩转新宝骏E300/E300Plus。',
          '1. 不同圈层UP主齐上阵，不同角度诠释新宝骏玩法，多兴趣领域曝光。',
          '2. 专题页面聚合UP主优质内容，发起话题讨论，鼓励用户创造内容，强化品牌认知和人群包数据沉淀。',
          '3. 信息流广告定向投放精准人群包，高效种草，完成整个销售链路的转化。'
        ]
      },
      {
        src: [IMGS.new_baojun_1, IMGS.new_baojun_2] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          '留资成本降低20%，CTR高于站内平均148%。',
          '成本达标的同时，在年轻人群中玩出新宝骏的梗。'
        ]
      }
    ]
  },
  {
    banner: {
      title: '2233花嫁IP联名，瞄准用户兴趣点',
      name: '招商银行',
      data: [
        {
          name: 'CTR高于站内平均',
          num: '57%'
        },
        {
          name: '办卡成本',
          num: '-30%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '营销策略及落地',
        desc: [
          '招商银行储蓄卡×B站2233花嫁IP联名，融入B站内容，让广告更原生。',
          '1. 招商银行与B站推出2233花嫁联名卡，并与大会员福利结合，从用户最关注的点切入，对目标人群进行精准投放。',
          '2. 标题&素材凸显B站联名和大会员福利，在品牌形象和客户产品的结合上，打破了原有广告单调的推广形式，提高点击率。',
          '3. 通过oCPC功能目标优化，降低办卡成本进行放量。'
        ]
      },
      {
        src: [IMGS.ZH_bride_1] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          'CTR高于站内平均57%，办卡成本降低30%。'
        ]
      }
    ]
  },
  {
    banner: {
      title: '全链路多阶段持续触达潜在玩家',
      name: '放开那三国',
      data: [
        {
          name: '日均注册用户数',
          num: '1000+'
        },
        {
          name: '用户付费率',
          num: '10%+'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '《放开那三国》是巴别时代年度手游大作，独创怒气技、武将培养等创新玩法，7月份产品公测在即，从游戏预约+预下载+公测推广的时间节点出发，借助B站效果广告产品矩阵打通全链路营销推广闭环。'
        ]
      },
      {
        src: [IMGS.SAN_GUO_1]
      },
      {
        title: '营销策略及落地',
        desc: [
          '【预约阶段】高CPM提升游戏推广热度，点击行为粗归因ios预约行为，打破投放壁垒，不断延展素材方向提升创意点击率，优化预约成本。',
          '【预下载】降本增效，集中曝光，素材方向以公测活动预热为主打利益点，提高用户留存。',
          '【公测下载】稳定投放，持续触达，CPC+oCPC单元结合投放，稳定前端成本的同时提升消耗量级，结合高净值付费人群包定向投放与已转化人群排除投放，提升后端付费率。'
        ]
      },
      {
        src: [IMGS.SAN_GUO_2] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          '日均转化1000+注册用户，用户付费率10%+'
        ]
      }
    ]
  },
  {
    banner: {
      title: '紧扣B站IP，玩转原生福利',
      name: '联通',
      data: [
        {
          name: '日申领量',
          num: '4000+'
        },
        {
          name: '领卡转化率',
          num: '56%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '中国联通×2233小电视IP联名卡，是中国联通与B站官方共同打造的系列免流卡，贴合B站社区生态和用户需求。2018年合作至今，此款联名卡一直深受B站用户追捧和喜爱。'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/tdCrc5mWv.png'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '营销策略及落地',
        desc: [
          '【优质内容】紧扣2233小电视IP，内容上围绕2233小电视形象宣传，迎合B站用户喜好。',
          '【原生福利】抛出利益点，以领取免流卡+免费兑换大会员双重福利活动吸引用户，在保证点击率的同时提升后端转化。',
          '【高效触达】同时用低价高ctr的素材对不同用户曝光，高效触达核心人群。'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/Y1LS~XOUsu.png'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          '【脑洞节】页面PV4500万+，投稿7000+，扭蛋机参与互动超23万，相关动态话题浏览超7300万。',
          '日申领量4000+，领卡转化率达56%'
        ]
      }
    ]
  },
  {
    banner: {
      title: '用趣味方式解锁效果广告',
      name: '潭州教育',
      data: [
        {
          name: '日线索量',
          num: '50000+'
        },
        {
          name: '表单成本低于行业平均',
          num: '20%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '潭州教育于2007年进入在线教育行业，是一家以在线教育为基础的科技文化公司，面向年轻人提供海量兴趣职业培训课程。而B站有着异常浓厚的学习氛围，在Z世代用户三大类兴趣偏好中，学习类高居首位。'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20200805/cffd107c73fc638c142399e72cb0cb0f/k5D~k0Ez4.png'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '营销策略及落地',
        desc: [
          '【内容方向】全资源投放，选课以兴趣学习为主，题材轻松有趣。',
          '【展示设计】素材以萌宠表情对比为主，融合年轻人审美潮流，提升用户好感，提升点击率。',
          '【特色留资】结合大部分用户还处于学生阶段、对自我探知充满了好奇，在落地页设计中多采取趣味测试题的方式，高效吸引客户留资。'
        ]
      },
      {
        src: ['https://activity.hdslb.com/blackboard/static/20200805/cffd107c73fc638c142399e72cb0cb0f/uSkcIO1s8-.gif'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          '日线索量50000+，线索有效率85%',
          '表单成本低于行业平均20%'
        ]
      }
    ]
  },
  {
    banner: {
      title: '海量内容素材，直达用户内心',
      name: '知乎',
      data: [
        {
          name: 'CPA下降',
          num: '15%'
        },
        {
          name: '留存质量综合',
          num: '9',
          unit: '分'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          '知乎拥有认真、专业和友善的社区氛围，结构化、易获得的优质内容；而B站拥有良好的社区氛围，和海量的视频内容。两者的合作，可谓一拍即合。'
        ]
      },
      {
        title: '营销策略及落地',
        desc: [
          '【宽领域】内容向的网服客户，适合以海量的内容展示作为素材，来筛选出绝大部分用户的新区方向。所以在投放中探索了各个方向的素材：两性情感、日常搞笑、科幻悬疑、知识学习、游戏娱乐等。',
          '【优体验】为了保证较好的下载率，落地页的内容呈现与外层素材保持内容一致。客户侧也根据用户的体验诉求，为每个素材定制了安装包，用户从某素材页面下载应用后，就可以直达该素材内容。'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/UL-pYO-Qz.png'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          'CPA下降15%，留存质量综合9分'
        ]
      }
    ]
  },
  {
    banner: {
      title: '精准内容，精准分发，精准种草',
      name: '自然堂',
      data: [
        {
          name: 'CTR高于同行业平均水平',
          num: '61%'
        },
        {
          name: '合作UP主',
          num: '31',
          unit: '位'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '合作背景',
        desc: [
          'Z世代用户（90/00后）已成为美妆主体用户，他们也被称为“美护新世代” 。聚集大量Z世代用户的B站，成为自然堂营销的重要阵地。'
        ]
      },
      {
        src: ['https://i0.hdslb.com/bfs/activity-plat/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/fH2EarGv1.png'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '营销策略及落地',
        desc: [
          '【内容精准】年轻用户对产品成分的越发关注、安全与功效越发重视；同时他们对偶像有着超高的忠诚，故通过明星代言、UP主试用，内容主打产品成分和测试，实现高效种草，帮助用户解决痛点问题。',
          '【分发精准】分素材定向up主粉丝人群+视频互动人群，精准触达目标用户。',
          '【优惠福利】素材内容提示优惠信息，如活动大促买10送5或买10享15。'
        ]
      },
      {
        src: ['https://activity.hdslb.com/blackboard/static/20200805/8a3e1fa14e30dc3be9c5324f604e5991/LYEidMOBPJ.gif'] // 图片，这里只要不是网络资源，如 https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2904197264,923708365&fm=26&gp=0.jpg 这种的话，就将图片发我，我这边处理，这里就写对应图片的名称，我自己来替换就好了
      },
      {
        title: '合作效果',
        desc: [
          'CTR高于同行业平均水平61%，单日互动50W为淘宝指数搜索引流。'
        ]
      }
    ]
  },
  {
    banner: {
      title: '杭州蓝铅笔运用兴趣关键词，全方位覆盖用户兴趣点',
      name: '杭州蓝铅笔',
      data: [
        {
          name: '表单成本',
          num: '-25%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '策略',
        desc: [
          '使用兴趣关键词，创意和落地页不断创新',
          'step1：定位绘画关键词相关人群包，精准定向对产品感兴趣的人群',
          'step2：优选老师作品作为素材，真实有效，吸引点击',
          'step3：丰富落地页内容，构图、人体、服饰、色彩、透视教程面面俱到'
        ]
      },
      {
        src: [IMGS.HZ_blue_qianbi]
      }
    ]
  },
  {
    banner: {
      title: '联通卡×B站IP，高效引流高效转化',
      name: '联通卡',
      data: [
        {
          name: 'CTR整体优化比例',
          num: '40%',
          unit: '+'
        },
        {
          name: '成本优化',
          num: '15%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '策略',
        desc: [
          '联动B站IP&大会员权益，打造B站特色流量卡投放',
          'step1：屏蔽高诈骗和低转化区域，保证转化人群质量',
          'step2：联动呈现B站品牌IP（2233娘&小电视）、B站大会员等元素，CTR整体优化比例超过40%',
          'step3：合作打通大会员权益，打造B站特色免流卡投放'
        ]
      },
      {
        src: [IMGS.liantong_card]
      }
    ]
  },

  {
    banner: {
      title: '拉勾×B站深度合作，联动B站元素投放',
      name: '拉勾',
      data: [
        {
          name: '点击率高于网服行业平均水平',
          num: '60%'
        },
        {
          name: '转化率高于网服行业平均水平',
          num: '85%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '策略',
        desc: [
          '与B站深度合作，联动B站元素投放',
          'step1：深度了解平台用户喜好',
          'step2：选取B站特色元素作为素材中的题材内容',
          'step3：将B站元素的素材呈现给到喜爱B站的用户群体，高效促成用户转化'
        ]
      },
      {
        src: [IMGS.lagou]
      }
    ]
  },

  {
    banner: {
      title: '剑与远征多类型创意投放，精准锁定用户',
      name: '剑与远征',
      data: [
        {
          name: '7日激活数同比增加',
          num: '80%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '策略',
        desc: [
          '多类型创意导向，锁定用户精准营销',
          'step1：以信息流小图为主要创意类型进行测试与投放',
          'step2：创意类型不断更迭交替，如游戏截图、技能图标、UP主等类型创意素材，结合商业兴趣定向，完成用户转化'
        ]
      },
      {
        src: [IMGS.AFK_Arena_2]
      }
    ]
  },
  {
    banner: {
      title: '起点阅读巧借oCPX能力优化成本，促成优质转化',
      name: '起点阅读',
      data: [
        {
          name: '安卓成本相比客户考核成本降低',
          num: '40%'
        }
      ],
      imgs: [
        IMGS.banner3,
        IMGS.banner4
      ]
    },
    content: [
      {
        title: '策略',
        desc: [
          'oCPX能力优化成本+多种投放方式',
          '使用多种投放方式宣传，借助oCPX能力优化成本',
          'step1：整合多种广告位，做精细化展示',
          'step2：选取用户属性相关联的ACG素材提升点击率，借助落地页选取文章内容，提升用户停留时长阅读文章，促成转化',
          'step3：借助oCPX能力，寻找优质流量同时降低成本，同时还能尝试在不同资源位拿到更多的优质转化流量'
        ]
      },
      {
        src: [IMGS.qidian_1, IMGS.qidian_2]
      }
    ]
  }

]

export const observeCaseList = [
  {
    title: '国货成长焕新在B站：用Z世代语言对话年轻人',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/AxlfHdUSuS.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/sYA9mjg79mD.jpg',
    desc: '得Z世代者得增长'
    // link: 'https://mp.weixin.qq.com/s?__biz=MzUyNDM5MzM2OQ==&mid=2247487191&idx=1&sn=b4153198f6685df0db0ea247bb663cac&chksm=fa2f4021cd58c937cf1d404aca0f6d815af94bbbd382cde99676e7962001ffd7e4c6009e316d&token=148499004&lang=zh_CN#rd'
  },
  {
    title: '官方答疑，首次对外！B站品牌号怎么玩？',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/H8iKmfrTja.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/U5gNTxUaIn8.jpg',
    desc: '首期B站小编面基趴顺利收官~撒花'
  },
  {
    title: '秋天的第一波梦幻联动，5大案例喜提B站花火最TOP',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/sg9bN0EliJP.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/fUPI8AcVmIh.jpg',
    desc: '优质UP主合作案例就在花火最TOP'
  },
  {
    title: '银行网点竟然有弹幕墙？快看看建行哔哩哔哩金融小站',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/N8y4FJd6LSN.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/w0ywaf0zWJW.jpg',
    desc: '谁说银行网点就必须要old school？'
  },
  {
    title: '8月，这些品牌在B站slay全场',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/p-mlq5~cTG8.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/2XfCEOXvM68.jpg',
    desc: '商业打赏新鲜出炉，来康康~'
  },
  {
    title: 'B有“料”丨新品直接买，抽奖定制化，B站新品日又又又搞事情？',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/o-rAG5F~ct6.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/mn2KUoduBrH.jpg',
    desc: '上新品，来B站！'
  },
  {
    title: 'B有“招” | B站营销产品上新，大视窗升级来袭',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/DdZ2zRgZ2nd.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/w8gXcr7b~se.jpg',
    desc: '大市场升级，速来围观'
  },
  {
    title: 'bilibili效果营销优秀合作伙伴高能RAP集结',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/SV3os9knBs.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/oXZ99n5RM.jpg',
    desc: '感谢大家的支持厚爱，预祝各位再创佳绩'
  },
  {
    title: '一图读懂2020 bilibili效果营销合作伙伴大会',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/5NVK3ZKdas.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/URzoXIsEc.jpg',
    desc: '引爆流行，增长B达'
  },
  {
    title: '速来围观！首期花火最TOP来啦',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/L7AhDYeIME.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/STM~GfoBB.jpg',
    desc: '优质UP主合作案例哪里看，花火最TOP帮你来盘点！'
  },
  {
    title: 'B站效果营销全面升级，Z+计划助力品牌长效增长',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/-M1lLzUNF.jpg',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/eO3RF2qSNG.jpg',
    desc: '2020 bilibili效果营销合作伙伴大会，引爆流行，增长B达！'
  },
  {
    title: '2020年度bilibili效果整合营销授权代理商名单公布',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/jphSkdTer.jpg',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/KNfgmVbbfP.jpg',
    desc: '未来他们将致力于为B站效果营销客户提供更优质的服务。'
  },
  {
    title: '29位合作伙伴荣获2020上半年bilibili效果营销优秀合作伙伴奖项',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/yZ~sMqYnp.jpg',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/jtRqk4p7li.jpg',
    desc: '合作共赢，共享增长。'
  },
  {
    title: 'B站新添好伙伴！首届效果营销代理商线下培训召开',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/cffd107c73fc638c142399e72cb0cb0f/i4-kwQtMbB.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/cffd107c73fc638c142399e72cb0cb0f/ha~NKpNwc.jpg',
    desc: '内附本期官方广告优化师名单'
  },
  {
    title: 'B站与拳头游戏达成战略合作，成为英雄联盟赛事独家直播合作平台',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/7lIer64AiT.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/w-xUFC7uI.jpg',
    desc: '上B站，看S10'
  },
  {
    title: '新鲜出炉 | bilibili花火平台开放首周亮点',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/s7ARyNGjjf.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/aNeG1Qsoh.jpg',
    desc: '高能预警，花火竟如此火爆！'
  },
  {
    title: '营销快报 | 7月这些品牌在B站玩嗨了',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/BIzi9VO7GD.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/E3tFC6Ca~.jpg',
    desc: '别等了，快上车！'
  },
  {
    title: 'B站营销哪家强，6月商业大赏来康康',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/kgpx3tULdv.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/E-m30TODA.png',
    desc: '6月重磅复盘'
  },
  {
    title: 'B站拍了拍你，请查收这份品牌营销手册',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/CnEpqbnfCP.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/BL~5xYJuB.png',
    desc: '文末附PDF版获取方式'
  },
  {
    title: '一图get新品在B站引爆之路',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/d~X6yPgdpv.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/fuUBJzCDq.jpg',
    desc: '发新品，上B站'
  },
  {
    title: '哔哩哔哩花火UP主商业合作平台授权10家核心代理商',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/PyudSyaTwd.jpg',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/oE2iQM9QM.jpg',
    desc: '合作共赢，全面提升内容营销服务能力'
  },
  {
    title: '哔哩哔哩花火UP主商业合作平台正式开放',
    banner: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/bVYIS3SuZf.png',
    telBG: 'https://i0.hdslb.com/bfs/activity-plat/static/20200812/8a3e1fa14e30dc3be9c5324f604e5991/5YzAdKAGK.png',
    desc: 'huahuo.bilibili.com'
  }
].map((item, index) => ({ ...item, id: index }))

export const observeDetailList = [
  {
    title: '国货成长焕新在B站：用Z世代语言对话年轻人',
    content: [
      {
        type: 'text',
        list: [
          '过去，你心中的国货可能是老土、俗套、质量不好；现在，提到“国货”，脑中浮现的或许已是新潮、品质佳、高能洗脑...当内容互动成为国货与Z世代沟通的重要方式，一出出精彩的国货“变身”大戏正全势上演。'
        ]
      },
      {
        type: 'text',
        list: [
          '万万没想到，有生之年还能目睹钉钉在B站“求饶”，五菱汽车在B站“说唱”，一加手机凭借“何同学”备忘录动画视频完成新品引爆，小米在粉丝的鬼畜问候和“卖CEO”之间反复横跳…越来越多中国本土品牌积极涌入B站，以优质内容为沟通媒介，实现品牌的焕新和心智转化。'
        ]
      },
      {
        type: 'text',
        list: [
          '新锐咖啡品牌三顿半与B站UP主狂阿弥围绕《当了四年UP主，我结婚了》视频进行合作，并推出限量微联名的定制产品，收获粉丝积极响应，该视频在B站收获播放量180万+。视频发布同期，三顿半咖啡线上销售也增长喜人，据CBNData消费大数据统计，2020年6月，三顿半在天猫销售额同比去年增速高达360%'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/~ByauRox1U.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/drr-ALw59cl.png'
        ]
      },
      {
        type: 'text',
        list: [
          '而作为99岁的老字号品牌，五芳斋也在B站积极投入年轻化内容营销“狂欢”，取得品牌焕新和新生意增长。今年5月，五芳斋在B站发布咸鸭蛋创意广告，“一本正经地戏精上身”——先是考试吃鸭蛋油条刷一波童年回忆，接着魔性美声解说，给大家带来了《舌尖上的中国》既视感。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/gXL9z8Lan~-.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/6EophgSEcNU.png'
        ]
      },
      {
        type: 'text',
        list: [
          '这搞怪“沙雕”又不失质感的创意走位，却恰好切中了年轻人的品味，获得了突出的传播效果；据统计，这支视频广告在B站收获播放量230万+，天猫数据显示，6月10日天猫欢聚日，五芳斋天猫旗舰店新客户增加92%，其中90后用户占比超过四成（42%）。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/jsk0gvdad~6.png'
        ]
      },
      {
        type: 'text',
        list: [
          '利用优质视频内容在B站营销的方式，不少国货品牌实力上演了什么叫“品效合一” “连种带拔”, 年轻人聚集的B站，已经逐渐成为新兴国货心智突破和传统国货品牌焕新的重要沟通阵地。'
        ]
      },
      {
        type: 'text',
        list: [
          '这不禁让人发问，为何国货品牌纷纷选择聚集在B站展开年轻化内容营销？'
        ]
      },
      {
        type: 'text',
        list: [
          '成长在中国综合国力和世界影响力快速的提升时期，年轻一代具备强烈的民族自豪感。同时，国货在品质、性能、价值、形象等方面迎来“蝶变”，也让年轻一代消费者对国货品牌的印象转好，整体本土文化认同感不断强化。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/gJtK756GsAd.png'
        ]
      },
      {
        type: 'text',
        list: [
          'B站数据显示，过去一年有9600万人在B站了解国风文化，精美汉服展示，百年妆容复刻，古风歌舞炫技，无一不演绎出年轻人对本土文化的热情。2019年，B站国创（国产动画）月活跃用户也首次超过日本番剧，成为B站第一大专业内容品类，截止2020年6月，国创播放量同比增长83%。'
        ]
      },
      {
        type: 'text',
        list: [
          'B站作为年轻人及中国传统文化爱好者的主要阵地，具备着优质的内容与社区交流的土壤，为国货品牌展开内容创意营销提供了天然的优势基础。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/ow0d3FfVYHh.png'
        ]
      },
      {
        type: 'text',
        list: [
          '包容、悦己、敢于尝新的95后进入消费前线，他们愿意为新潮、高品质、有创意的产品买单，也更看重商品之外的品牌文化与价值。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/T3MjbzhkDSX.png'
        ]
      },
      {
        type: 'text',
        list: [
          '而B站作为年轻人高度聚集的平台之一，不论是老国货焕新，还是新国货初入市场打开局面，都可以在B站精准触达各个兴趣圈层的年轻人，并通过他们对好内容好产品乐于分享的开放态度，引爆全网流行，获得新增长。这也使得在B站做新国货营销，成为大势所趋，品牌亮眼营销案例层出不穷。'
        ]
      },
      {
        type: 'text',
        list: [
          '其中，新锐美妆品牌Girlcult与B站UP主朵朵花林合作，围绕NASA联名产品共创太空主题视频，老牌国货云南白药携手B站人气美食纪录片《人生一串2》，借创意剧场让烤串店主出镜安利牙膏，均是在B站备受年轻消费者热捧的品牌内容营销案例。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/eLgcwqbIr0K.png'
        ]
      },
      {
        type: 'text',
        list: [
          '伴随本土文化认同增强，年轻人逐渐成为消费主力的同时，也积极成为国货品牌的操盘手；而B站年轻、开放、包容的社区氛围，恰好为优质新品牌孵化和成长提供了良好土壤。'
        ]
      },
      {
        type: 'text',
        list: [
          '以B站UP主“小豆蔻儿”为例，她在2016年创办了汉服原创设计品牌“十三余”。2019年，十三余在淘宝天猫平台成交额突破2亿，今年在天猫的成交同比超370%，带动汉服突破“圈地自萌”成为潮流消费。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/kEDYin10uNZ.png'
        ]
      },
      {
        type: 'text',
        list: [
          '无独有偶，B站知名球鞋评测UP主“zettaranc” （简称Z哥）也自创品牌，在B站发布“国货狂欢夜”直播活动，边“说相声”边送出自家球鞋，深受粉丝热捧。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/cffd107c73fc638c142399e72cb0cb0f/FwSgv44OIub.png'
        ]
      },
      {
        type: 'text',
        list: [
          '从Made in China 到Made for China，内外因素交织下，全新的本土发展格局，为国货品牌化发展带来不可多得的机遇。未来10年，将是互联网创造新品牌的10年，也是新国货的黄金10年。'
        ]
      },
      {
        type: 'text',
        list: [
          '对很多品牌来说B站是同年轻人交流的重要渠道之一，是实现“新增长”的价值洼地。而B站UP主依靠专业力、创意力，及与年轻用户建立的高粘性信赖关系，成为新老国货品牌与年轻消费者沟通的 “转译者”。'
        ]
      },
      {
        type: 'text',
        list: [
          'B站UP主善于使用符合年轻人个性的语言体系和审美趣味与用户互动，他们既能发挥创意脑洞吸引用户“种草”，又能依靠他们对产品的分钟级专业深度解读，以及与用户间建立的长期信赖关系，更自然地缩短了年轻人消费决策的路径，促进用户在后续成功“拔草”，达到“品牌内容出圈+产品种草转化”的双向品牌效果。'
        ]
      },
      {
        type: 'text',
        list: [
          '传统国货品牌和新兴国货品牌，都在不断积极地拥抱Z世代年轻人。在B站融入年轻人独特的话语体系，通过多元的年轻化内容营销、与优质UP主的共创联结，获得不断的焕新和创新。'
        ]
      },
      {
        type: 'text',
        list: [
          '这，或许也将成为品牌增长持久的动力源泉。'
        ]
      }
    ]
  },
  {
    title: '官方答疑，首次对外！B站品牌号怎么玩？',
    content: [
      {
        type: 'text',
        list: [
          '上周五，首期B站品牌号小编“面基趴”顺利收官，从干货内容分享到圆桌话题讨论，我们的产品同学和运营“大神”与来自近30个品牌的B站小编，围绕品牌号如何在B站做品牌营销、如何提高播放量、如何涨粉等重点“KPI问题”， 畅聊3小时，针对性答疑，干货满天飞。'
        ]
      },
      {
        type: 'text',
        list: [
          '很多报名的B站品牌号小编由于路途遥远，未能前往B站本站参与讨论，于是我们摘录了现场的部分问答内容，和大家一起分享，希望能为想要玩转B站的品牌号小编们带来帮助！'
        ]
      },
      {
        type: 'text',
        list: [
          'Q：越来越多品牌在B站开号，那到底应该怎么样去定位品牌号？',
          'A：不要老觉得自己是广告主，在B站，品牌号更像是B站UP主，想要融入B站“内容+社区”生态，有人设、重内容、强互动、长期运营才是王道。'
        ]
      },
      {
        type: 'text',
        list: [
          'Q：我们才刚刚开始做品牌号，如何度过冷启动？',
          'A：一般来说，冷启动期结束的标志是产出了一条播放量10W+的视频内容，在这之前就要分三步走：打造人设、建立信任，最后创造爆款。'
        ]
      },
      {
        type: 'text',
        list: [
          '立人设就是定位在Z世代视野中的品牌形象，从头像、到简介、再到头图，每一个细节都不能放过。以头像为例，想要融合B站文化，结合自身品牌和产品特性的头像就是个吸睛的好办法。阿里云、中国联通官方客服、荣耀手机、拼多多都是其中的典型代表。'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/IdxV-fMiwG.jpg']
      },
      {
        type: 'text',
        list: [
          '同样，简介也是打造人设、建立信任的的好渠道，介绍自己的优势、直接互动求涨粉、简单直白发福利，都是既有高粉丝品牌号的“诀窍”。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/4Re7VoXSAQ.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/A9r8mky~kP8.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/BUtEJGXRivo.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '头图同样不容忽视，B站大会员即可配置背景图，而品牌号更有优势，目前品牌号头图背景视频功能正在内测中，开放后B站小编们可以用30秒至60秒的短视频，充分介绍“我是谁，我的产品是什么，厉害在哪里”。'
        ]
      },
      {
        type: 'text',
        list: [
          '此外，B站品牌号的品牌定制页功能正在内测，针对不同行业，可以配置不同的TAB入口，真正做到“千号千面”，帮助品牌号深度优化人设。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/yfF~8v1t2b8.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/a6q6a~PxYAB.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/E0PTc7ifKd9.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          'Q: 那打造爆款有什么窍门？',
          'A：爆款出街需要天时地利，但打造优质内容还是有方法论。可以总结为：紧抓热点、改编不停、融入社区文化。'
        ]
      },
      {
        type: 'text',
        list: [
          'B站流行内容众多， 达成万级播放量的品牌号投稿稿件中，投稿在生活区的稿件达标率第一，而平均成绩最为突出的是如鬼畜、音乐和动画分区，游戏、学习区也是B站用户的高活跃区。'
        ]
      },
      {
        type: 'text',
        list: [
          '踩中热点后将热梗进行到底，并用B站用户最喜欢的方式表现，最后再结合品牌特色差异化输出，就离爆款更近了一步。'
        ]
      },
      {
        type: 'text',
        list: [
          '疫情期间走红的钉钉，从求饶鬼畜，到每月更番，都是踩准了这个做爆款内容的方法论。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/2eGhSqNqYil.png'
        ]
      },
      {
        type: 'text',
        list: [
          'Q: 冷启动期过后，品牌号如何持续涨粉？',
          'A：还是三个关键点——内容为王、粉丝福利、矩阵互动。'
        ]
      },
      {
        type: 'text',
        list: [
          '首先内容上坚持打造优质内容的方法论，这是必备基础。'
        ]
      },
      {
        type: 'text',
        list: [
          '比如抽奖，后台数据显示，抽奖的动态的平均转发量是自然动态的38倍。目前B产品牌号产品功能支持动态抽奖，方式为关注+转发，未来抽奖功能将升级为点赞、评论、关注时长等多种抽奖方式的组合，助力小编们运营涨粉。'
        ]
      },
      {
        type: 'text',
        list: [
          '比如评论装扮、动态装扮功能，点击用户的评论和动态装扮即可实现跳转，意味着每一条评论和动态都可能是用户的入口。'
        ]
      },
      {
        type: 'text',
        list: [
          '最后是互动，与其它品牌号、UP主或主站各官号联动，可以资源互通、扩大影响，还可以相互带量，转化粉丝。阿里动物园的矩阵互动就是很好的示例。'
        ]
      },
      {
        type: 'text',
        list: [
          'Q：有没有更具体的运营技巧？',
          'A：从内容生产到内容发布，都是有技巧可寻。'
        ]
      },
      {
        type: 'text',
        list: [
          '内容生产上，要重视封面和标题，用户决定是否观看一个视频，最多只花费2秒钟时间，利用这2秒钟激发用户兴趣、吸引点击，封面和标题尤为重要。'
        ]
      },
      {
        type: 'text',
        list: [
          '封面图需要主题明确，画质清晰，即使是暗色背景，拍摄主题及文字也必须明亮突出；封面展示面积小，务必保证文字能被一眼看见。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/qVWAX~XXC9v.png'
        ]
      },
      {
        type: 'text',
        list: [
          '标题要做到简练文字，精准表达。减少无效信息，才让用户快速理解视频重点，并迅速产生兴趣，点击视频。'
        ]
      },
      {
        type: 'text',
        list: [
          '内容发布上，选择用户活跃的时段发布内容，将会获得更多的曝光量。同时，也可以合理使用多种标签。标签是UP主对视频的分类与定义，对视频添加合适的标签，能帮助视频触达对该标签感兴趣的用户，获得更多曝光。'
        ]
      },
      {
        type: 'text',
        list: [
          'Q：品牌号产品功能上未来还有哪些升级？',
          'A：除了抽奖能力升级，未来我们还会在热点跟踪推送、全盘数据分析等方面对B站品牌号产品进行升级迭代。'
        ]
      },
      {
        type: 'text',
        list: [
          '比如未来会打造创意中心，提供灵感库和模板库，降低内容制作成本。还会将稿件作为创意，品牌号个人空间作为落地页，开通效果广告投放。'
        ]
      },
      {
        type: 'text',
        list: [
          '同时，既有的“活动冠名、特约赞助、奖品赞助”等不同量级的合作、定制的品牌营销活动结束后后，品牌号空间内将会推出固定的入口，既保持时效性，又能沉淀历史话题。'
        ]
      },
      {
        type: 'text',
        list: [
          '以上内容整理自首期B站小编“面基趴”，基于B站商业产品能力和用户调研结果，我们近期还会推出B站品牌号品牌营销通案，敬请期待！'
        ]
      }
    ]
  },
  {
    title: '秋天的第一波梦幻联动，5大案例喜提B站花火最TOP',
    content: [
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/7VtOhUkNQG.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '手工UP纸模打造科技造型'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/MzCuv7uFBtX.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '手工区UP主雁鸿耗时100个小时，用纸模做出朋克且带有科技感的造型，简单的纸模手工和极具未来科技妆容结合，再搭配上以科技感著称的外星人电脑，创意完美贴合产品特色！'
        ]
      },
      {
        type: 'text',
        list: [
          '美食UP还原拉面制作过程'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/OBe-s0aSfzx.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '人们觉得拉面说美味，但是很少有人知道美味的背后“粒粒皆辛苦”。一碗好吃的拉面说拉面是如何做出来的？让美食区UP主为你还原拉面说拉面的制作过程，展示拉面说美味背后的辛苦！注意！前方高能！'
        ]
      },
      {
        type: 'text',
        list: [
          '时尚UP假扮公主潜入城堡'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/a20OL1~~wKB.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '哪位女生没有一个公主梦？坐在城堡里喝着下午茶，吃一颗入口即化的巧克力。时尚UP主suzy潜入城堡，让德芙做指引，为所有的少女完成了一场公主梦。'
        ]
      },
      {
        type: 'text',
        list: [
          '数码UP趣味教学做特效'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/0kAa~MmvC4j.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '数码区UP主利用戴尔XPS17趣味教学如何利用空间透视关系，打造视觉上的“欺骗术”。让你做出有趣的特效效果后期，把产品通过教学的方式放置到使用场景中，寓教于乐的同时，让观众认可品牌优质功能。'
        ]
      },
      {
        type: 'text',
        list: [
          '生活UP自驾旅行探火山'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/8Fp93YC3iyT.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '自驾5个多小时，生活区UP主到达了位于乌兰察布的火山群 - 察哈尔火山群。一汽马自达CX-4带领UP主和观众一起来到人间美景，探寻火山遗迹。一起遇见银河。一辆好车，可以带你走遍山川江河，看遍四季美景。'
        ]
      }
    ]
  },
  {
    title: '银行网点竟然有弹幕墙？快看看建行哔哩哔哩金融小站',
    content: [
      {
        type: 'text',
        list: [
          '提起办理银行传统业务的线下网点，你是不是总能脑补“排队叫号”的画面？或者提及传统银行网点，是不是容易想到相对严肃的氛围？'
        ]
      },
      {
        type: 'text',
        list: [
          '但如果银行网点画风突变，一眼望去，都开始“发车”了呢？”'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/Z2Ss3uz0aq.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '又或者，当工作人员小姐姐和UP主一起跳起宅舞，让你感受到不一样的热情，有木有让你想去这个氛围活泼的特别网点“看看”？'
        ]
      },
      {
        type: 'text',
        list: [
          '起码有些UP主心动了。'
        ]
      },
      {
        type: 'text',
        list: [
          'UP主Bigger研究所在“探店”之后都不禁感慨，“没想到一向严肃的银行也B站化了，这个神秘力量真是太强大了！”'
        ]
      },
      {
        type: 'text',
        list: [
          '看来有了小电视的加持，金融网点给人的那种距离感也减弱了。'
        ]
      },
      {
        type: 'text',
        list: [
          '在今年的开学季，以上海为起点，中国建设银行（下文简称建行）携手B站将在北京、天津、杭州、南京、西安、武汉、长沙、成都、广州、深圳、厦门等十多个城市的众多高校周边，打造百家B站主题金融小站。'
        ]
      },
      {
        type: 'text',
        list: [
          '位于复旦大学旁的建行上海国定路支行网点，就在9月10日率先“变身”为全国首家 “哔哩哔哩主题金融小站”。'
        ]
      },
      {
        type: 'text',
        list: [
          '每家金融小站都将融合大量B站元素。走进国定路支行网点的大堂，第一眼就能看到铁轨上停着一辆超大的蓝色列车“哔哩哔哩列车金融号”。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/KDLqp8HItuz.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '列车作为年轻人开启人生征途的符号有着特殊意义——很多年轻人从五湖四海乘坐高铁来到高校所在的城市，开启人生新阶段；而在金融小站开动“专车金融号“”，预示着建行和B站共同打造专车帮助年轻人开启新的旅程。'
        ]
      },
      {
        type: 'text',
        list: [
          '在第二家哔哩哔哩主题金融小站——建行北外支行网点外，柜台和客户休息区都被改造成了“售票处”“接站台”“候车区”等特色区域，在不同区域还能看到挂满弹幕的弹幕墙。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/W8fRDl5olPA.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '此外，金融小站还设计了丰富的互动体验环节，触屏游戏更是巧妙“植入”了金融知识以及建设银行为年轻人打造的金融服务。'
        ]
      },
      {
        type: 'text',
        list: [
          '看到UP主萌爱moi的视频，有用户心动后都忍不住要跟风现场打卡了。有人还在评论区都开玩笑说，“B站的硬币能在建行兑现了？！”'
        ]
      },
      {
        type: 'text',
        list: [
          '当然啦，这次B站同建行开学季的合作不止于共同打造线下IP金融小站的合作。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/b11jsuxKVuc.png'
        ]
      },
      {
        type: 'text',
        list: [
          '对无法到现场参与“线下打卡”的年轻人，建行线上联合B站发起了召集令活动，共同打造开学话题#小电视喊你报道啦#，召集用户分享开学见闻，视频投稿分享返校心情，赢取精心准备的开学奖品。'
        ]
      },
      {
        type: 'text',
        list: [
          '连知名UP主老番茄也在借着#小电视喊你报道啦#的话题分享了当年“开学季”的种种回忆~'
        ]
      },
      {
        type: 'text',
        list: [
          '通过哔哩哔哩金融小站的线下网点布局，金融小站将应用数字化技术，赋予网点连接高校周边周能力，并将金融产品无缝嵌入生态场景中。'
        ]
      },
      {
        type: 'text',
        list: [
          '在这个开学季，B站与建行的合作致力于为年轻人提供更有温度的、更美好的“金融+生活”服务；基于年轻人喜爱的“年轻化”文化融合，这次合作也是双方打造“Z世代”社区生活生态圈的一次重要尝试。期待B站和建行能通过合作给年轻人能带来更多惊喜。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/QtH18U8qGDu.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '想要解锁更多金融服务，了解更多金融知识，还不赶快去哔哩哔哩金融小站的现场了解一下？'
        ]
      }
    ]
  },
  {
    title: '8月，这些品牌在B站slay全场',
    content: [
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/7eAGeM~15M.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/A5Wfc3Pmgml.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/827XPZ8DRRu.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/e8IyCZnqFHL.jpg'
        ]
      }
    ]
  },
  {
    title: 'B有“料”丨新品直接买，抽奖定制化，B站新品日又又又搞事情？',
    content: [
      {
        type: 'img',
        list: [
          ''
        ]
      },
      {
        type: 'text',
        list: [
          '新品营销解决方案“B站新品日”上线以来',
          '大牌汇聚，新品齐发',
          '不仅“直播+视频”两开花',
          '更有人气UP主齐助力',
          '全面升级后的B站新品日',
          '从新品展示到电商转化到互动体验',
          '全链路各显神通',
          '这不，资生堂和vivo就做了首轮尝鲜'
        ]
      },
      {
        type: 'text',
        list: [
          '资生堂×B站新品日'
        ]
      },
      {
        type: 'text',
        list: [
          '资生堂近日推出“红腰子”新品',
          '直播期间',
          '资生堂邀请到多位人气UP主'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/w9igvBKouw.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '@中国BOY超级大猩猩',
          '玩起背后画画游戏，笑料百出',
          '看完再也忘不掉红腰子的小蛮腰'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/Q9MRidvZd9.gif'
        ]
      },
      {
        type: 'text',
        list: [
          '多位人气UP主为直播充能',
          '直播人气峰值突破100万',
          '相关话题用户投稿数同比增长30%',
          '同时，资生堂品牌号也涨粉40%'
        ]
      },
      {
        type: 'text',
        list: [
          '资生堂这次还尝试了B站新品日新功能',
          '定制弹幕抽奖',
          '只需关注资生堂',
          '发送定制弹幕“出圈横着走”',
          '就有机会获得大奖'
        ]
      },
      {
        type: 'text',
        list: [
          '开启直播间新品橱窗',
          '点击即可跳转至商品详情页',
          '促成购买'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/-~aBdS4VHj.gif'
        ]
      },
      {
        type: 'text',
        list: [
          'vivo x B站新品日 '
        ]
      },
      {
        type: 'text',
        list: [
          'vivo也带着新品赶来与大家见面',
          '在vivo S7发布会上',
          '邀请到B站UP主@宝剑嫂和SING女团',
          '作为vivo S7的出道见证官'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/h-ejbyr~z9.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '@宝剑嫂现场挑战极速换妆',
          '用一首歌的时间换上唐代盛装',
          '云想衣裳花想容',
          '春风拂槛露华浓',
          '怎样才能留住妆发的颜色呢？',
          '宝剑嫂的秘密武器就是',
          '——vivo S7 4400万前置摄像头'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/2JTCn2yvEQ.gif'
        ]
      },
      {
        type: 'text',
        list: [
          'vivo也尝鲜了B站新品日新玩法',
          '在直播间定制抽奖引爆用户互动',
          '直接点击，参与宝箱抽奖',
          '天选时刻，即可弹幕抽奖'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201012/8a3e1fa14e30dc3be9c5324f604e5991/FuUwwhDzW1.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '新品日，新玩法',
          '充分调动用户的互动兴趣',
          '提升发布会沉浸感和愉悦感',
          'vivo发布会直播间人气峰值近百万',
          'vivo品牌号增粉达30%'
        ]
      },
      {
        type: 'text',
        list: [
          '怎么样，B站新品日是不是很有料？',
          '更多花样玩法',
          '请锁定“哔哩哔哩商业广告”'
        ]
      }
    ]
  },
  {
    title: 'B有“招” | B站营销产品上新，大视窗升级来袭',
    content: [
      {
        type: 'text',
        list: [
          '最新产品动态',
          '最热营销玩法',
          'B有“招”',
          '招招管用'
        ]
      },
      {
        type: 'text',
        list: [
          '今日放大招',
          '大视窗升级来袭',
          '首刷大视窗，曝光高能',
          '视频承载故事，创意高能',
          '多链路转化，效果高能',
          '助品牌一秒入“戏”'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20201014/cffd107c73fc638c142399e72cb0cb0f/vqJAZPtSMN.gif'
        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://b23.tv/fVrOj1'
      }
    ]
  },
  {
    title: 'bilibili效果营销优秀合作伙伴高能RAP集结',
    content: [
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/7LWLg8gJY.jpg']
      },
      {
        type: 'text',
        list: [
          'YO BRO',
          'Where are you going',
          '没事我溜达',
          '这不是B站要颁奖了吗',
          '颁啥奖啊？有你吗  有我啥',
          '首先颁发的是客户最佳合作伙伴奖',
          '他们有',
          '',
          '潭州教育  你要学的潭州都有  有很多',
          '莉莉丝的新游戏  万国觉醒不啰嗦',
          '战双帕弥什  英雄互娱末世科幻不多说',
          '游族山海镜花  我们一起探索',
          '',
          '长期合作成就奖  潭州教育再次来袭',
          '腾讯游戏一人之下  玩的我很着迷',
          '梦幻西游网易游戏  体验自由的肆意',
          '',
          '新锐合作奖的朋友你们别着急',
          '七猫免费小说  让你免费看书100年',
          '建模大师大师建模  快乐就在眼前',
          '不要羡慕技术宅  开课吧有IT男',
          '用了乐学你就知道  高考一点都不难',
          '阿里游戏幻想到战略 一统三国的大陆',
          '游戏公国机动战队 原创机战特别酷',
          '万达院线游戏  异次元大作战约不',
          '',
          '效果整合营销创新奖的兄弟',
          '到你们节目',
          '新宝骏系列  让你的座驾充满科技',
          'ZOZO潮品穿搭  ZOZO看很潮的日系',
          '网易严选全屏视频落地  攻占你的视觉地',
          '',
          '谢谢这么多客户一直陪着B站 不离不弃',
          '感谢今天大家的到来',
          '不要走开 下边还有'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/vheU~mnMpt.jpg']
      },
      {
        type: 'text',
        list: [
          '刚才就是咱家获奖的客户',
          '客户？还有别的获奖的吗？',
          '那肯定不能亏了代理呀',
          '注意  前方高能预警',
          '',
          '代理最佳合作伙伴奖 名次不分高低',
          '首先我要介绍  还是圈粉老牌代理',
          '拼命三郎汨汨难认但是好记',
          '当然真龙销售人多还在深耕教育',
          '执享游戏行业门儿清还爱拼搏',
          '',
          '所以下边介绍获得突破合作奖的',
          '常州初见初见业绩冲的很猛',
          '南京灵动稳字当头一点不放松',
          '柏烨互动不断拓展是华南精英',
          '微思敦是尖子生搞得好运营',
          '老熟人几维鸟  分分钟冲上排行榜',
          '',
          '下面跟着我来继续揭晓创新营销奖',
          '美妆大拿方案多 UP主多的梨白',
          '迎难而上大项目集中的就是领凯',
          '念念不忘信息流的墨幸  商单玩的最嗨',
          '',
          'bilibili再次感谢大家一直支持厚爱',
          '以上就是咱家获奖的朋友',
          '预祝各位再创佳绩'
        ]
      },
      {
        type: 'link',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV1uK411M7gA?p=1'
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/Ffz-domWWO.jpg']
      }
    ]
  },
  {
    title: '一图读懂2020 bilibili效果营销合作伙伴大会',
    content: [
      {
        type: 'img',
        cls: 'no-gap',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/6JfP3frIb.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/LrcQPzarbP.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/KbyekeAs93.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/hHBohRjVJZ.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/U0sGB9jrjM.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/mjGRBsGFBO.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/D9OO~EXmqB.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/92zLweKqmk.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/FpeLonhndj.jpg'
        ]
      }
    ]
  },
  {
    title: '速来围观！首期花火最TOP来啦',
    content: [
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/dXSBDIQHz.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/FtRuLdoSAD.gif'

        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV1154y1i7dW'
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/Qfm8b83NEK.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/ZmxbqXsvce.gif'
        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV1A5411a71E?from=search&seid=8745287546398474740'
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/0ln5hcnPiO.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/3loNODXpIo.gif'
        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV13K411n7BA?from=search&seid=14415681268951870681'
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/lPiReJFzG.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/XOvhsLiN-S.gif'
        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV1EK411n78L?from=search&seid=15266252457457751588'
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/HBSAKD0~rm.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/~~LWJb9y~z.gif'
        ]
      },
      {
        type: 'link',
        cls: 'tip',
        title: '视频链接',
        link: 'https://www.bilibili.com/video/BV1ah411o7Po?from=search&seid=2068672529894605205'
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/MyKvjPoLP6.jpg'
        ]
      }
    ]
  },
  {
    title: 'B站效果营销全面升级，Z+计划助力品牌长效增长',
    content: [
      {
        type: 'text',
        list: [
          '8月20日， 2020 bilibili效果营销合作伙伴大会在上海举办。此次大会上，B站表明将继续推动商业化升级、与合作伙伴分享B站成长的长期红利，并重磅发布效果营销“Z+计划”，从资源、能力、品类、服务四个维度，全面助力品牌在Z世代人群消费力崛起的黄金时代，在B站引爆流行，实现长效增长。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/QqTJVQNz7.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/AdffwHkBB9.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '哔哩哔哩副总裁刘斌新在主题为“新商业，新增长”的演讲中表示，在移动互联网流量红利见顶后，B站的用户规模仍在保持健康增长，截至2020年Q1，B站月活用户超过1.72亿，同比增长70％。与此同时，B站的内容生态百花齐放，有7000多个核心文化圈层，200多万个文化标签，内容品类已经能覆盖并代表中国Z世代的内容需求和喜好。',
          '',
          '“住在B站的优质用户，是当前必须抢夺的增量人群。”刘斌新在会上分析道， B站用户是一群兴趣驱动、乐于尝鲜的视频时代原住民。他们愿意黏在B站，是因为B站搭建了一个优质的内容生态，能够满足他们不断增长的视频消费与兴趣需求。 '
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/HyJfpQIDaJ.jpg',
            msg: '哔哩哔哩副总裁刘斌新'
          }
        ]
      },
      {
        type: 'text',
        list: [
          '用户和内容的增长，拓宽了B站的商业空间，广告收入和eCPM同比均大幅增长。对于广大品牌主来说，也意味着B站的营销投放红利期才刚刚开始。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/oBh83CH9sg.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          'B站背后的巨大增量市场是广告主必须抢占的新机遇。B站营销“一键三连”，通过品牌广告原生曝光，内容营销沟通心智，效果营销收获转化，品牌号完成品牌内容、数据资产沉淀，让B 站生态价值为营销赋能，帮助广告主连接用户、连接增量市场，最终实现增长。',
          '',
          '效果营销作为B站目前营销一键三连的重要一环，一直备受关注。基于此，哔哩哔哩正式推出效果营销“Z+计划”，在资源、能力、品类、服务4个方面，为效果广告客户赋能。',
          '',
          '具体来说，“Z+资源”的主要落点是场景扩展和流量开放。未来B站将把视频、直播等更多场景引入效果广告的落地场景中，同步扩展更多流量渠道。',
          '',
          '“Z+能力”，主要围绕产品、算法进行升级。产品侧包括从展现到转化的完整视频化体验、动态创意工厂、内容IP的孵化与赋能；在算法侧，把深度学习模型用在CTR、CVR预估等环节，进而帮助代理商、广告主提升效率。',
          '',
          '“Z+品类”，将依托B站多元圈层的优势，纵深开拓行业，扶持新锐品类，帮助传统品类焕新。',
          '',
          '“Z+服务”，则落实到运营支持、工具提效、代理培训几个方面，提供最佳服务响应。',
          '',
          '刘斌新表示，未来“Z+计划”将助力品牌连接增量消费群体，共享B站长效增长红利。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/EqEIoNndZ6.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '转译用户路径是效果增长的重中之重，此次bilibili效果营销合作伙伴大会上，哔哩哔哩营销中心总经理王旭首次对外分享了让效果营销原生化、本地化的“制胜三招”——视频化、IP化、整合营销化，帮助品牌转译用户路径，实现效果营销“开挂”。'
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/jjiFmiruuy.jpg',
            msg: '哔哩哔哩营销中心总经理王旭'
          }
        ]
      },
      {
        type: 'text',
        list: [
          '素材视频化，是指将投放的信息用视频的表现方式呈现在从外到内的各个路径中，契合B站用户行为习惯，更生动、吸引观看、刺激转化。从效果来看，符合用户预期的视频化可大幅降低新用户消费成本。',
          '',
          '素材IP化，是指通过创造或者借力高价值IP，打造正向内容影响力。王旭指出，B站用户对喜欢的IP投入是全方位的， IP化物料可以助力效果广告全链路高效转化。',
          '',
          '整合营销化则是投放打法上的最佳选项，将契合用户需求的内容，从直效广告到多效转化、从单链路到多链路进行投放。从数据来看，整合营销式的转化更容易打动用户，也逐渐成为趋势。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/05bp4j2ItC.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '在纷繁复杂的现代营销环境下，如何获得Z世代消费者的青睐、玩转B站效果营销，是很多广告主和代理商的痛点。会上，哔哩哔哩营销中心副总经理权硕给出了四个助力有“效”营销的关键词。',
          '',
          '首先是转译。对于广告主而言，找到转译用户的有效路径至关重要，视频化、IP化、整合营销化带来的效果也有数据可以证实。以视频化为例，物料消耗增长率更快，视频封面提升超过30%，视频落地页提升更能达到600%以上。',
          '',
          '其次是兴趣。找准兴趣行业和投放兴趣素材是一套优势明显的组合拳。一方面，找到符合B站用户兴趣的行业和产品，可以明显提高转化；另一方面， B站用户感兴趣的素材能够保证新奇感，类UP主封面风格、精美立绘插画风格，表情包一类的素材都有不错的CTR数据。'
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/TygT26d1hF.jpg',
            msg: '哔哩哔哩营销中心副总经理权硕'
          }
        ]
      },
      {
        type: 'text',
        list: [
          '再次是规模。规模化投放可以保证持续放量，从数据来看，预算规模整体与转化率成正比，规模化投放趋势也较为明显，2020年上半年，B站效果营销框架客户持续增长。',
          '',
          '最后是数据。广告主和代理商可以借力oCPX提效，通过数据回传优化，最终实现oCPX整体消耗走高。此外，还要学会利用数据分析进行扩圈投放，灵活运用DMP，可以找到更多UP主与活跃用户。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/LUZE-PN1GM.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '在“Z+计划”的基础上，B站效果营销将在服务能力和产品技术上大力推动升级，为广告主和代理商提供更多支持，为品牌营销提供增长引擎。',
          '',
          '在服务能力升级方面，未来B站将推出多类型赋能培训，针对性解决投放问题，覆盖广告主和代理商的高层、运营、销售各个层级。同时将加强与品牌主的市场联动，放大营销影响力。',
          '',
          '在技术能力升级方面， B站将深度优化产研-商业中台矩阵，扩充优化产品资源，强化产品能力，提升投放效率，完善数据定向，推动数据开放。'
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/6vVNjZwfxo.jpg',
            msg: '哔哩哔哩商业技术部总经理朱聪'
          }
        ]
      },
      {
        type: 'text',
        list: [
          '哔哩哔哩商业技术部总经理朱聪在会上介绍，未来B站将会通过底层技术迭代，开拓更多场景，为广告主提供更多有效触达的形式。在搭建oCPX能力方面，目前B站oCPX的有效优化率和消耗覆盖率已经比肩行业内高标准水平，接下来在“Z+计划”的推动下，将继续加强深度转化模型的建设。',
          '',
          '广告投放端一手抓投放效率，简化投放流程、提升运营效率、审核效率，为代理商进一步减负；另一手抓投放体验，降低产品学习成本，优化全流程体验。',
          '',
          '同时，在数据挖掘上继续投入更多人力，加强内容数据语义分析，互动弹幕数据分析以及UP主数据深度挖掘，赋能效果广告更精准更全面。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/SfuR8efp03.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '在活动现场，ZOZO中国区首席营销官张明昊和潭州教育首席战略官周蔚作为广告主代表，也分别分享了在B站效果营销带来的成果，以及各自玩转B站效果营销的硬核经验。'
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/tvdsOR-2a-.jpg',
            msg: 'ZOZO中国区首席营销官张明昊'
          }
        ]
      },
      {
        type: 'img',
        list: [
          {
            src: 'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/E8W9o2vZ9M.jpg',
            msg: '潭州教育首席战略官周蔚'
          }
        ]
      },
      {
        type: 'text',
        list: [
          '活动最后，B站还为2020年上半年bilibili效果营销优秀合作伙伴颁奖，并公布了2020年度bilibili效果整合营销授权代理商名单。'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/DuGea6iG3h.jpg']
      }
    ]
  },
  {
    title: '2020年度bilibili效果整合营销授权代理商名单公布',
    content: [
      {
        type: 'text',
        list: [
          '8月20日， 在2020 bilibili效果营销合作伙伴大会上，B站公布了2020年度bilibili效果整合营销代理商名单，并正式进行授权。未来他们将致力于为B站效果营销客户提供更优质的服务。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/hhi93~Az2.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/1V-CZmKaEL.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '哔哩哔哩副总裁刘斌新、哔哩哔哩营销中心总经理王旭、哔哩哔哩营销中心副总经理权硕为代理商授权',
          '',
          '具体名单详见下图↓↓↓'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/6GzzHXK3Fw.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/GlYbI0eWzK.jpg'
        ]
      }
    ]
  },
  {
    title: '29位合作伙伴荣获2020上半年bilibili效果营销优秀合作伙伴奖项',
    content: [
      {
        type: 'text',
        list: [
          '8月20日，在2020 bilibili效果营销合作伙伴大会上，B站为29位合作伙伴颁发2020年上半年bilibili效果营销优秀合作伙伴奖项。',
          '',
          '现场共计颁出包括最佳合作伙伴奖、长期合作成就奖、新锐合作伙伴奖、效果整合营销创新奖、创新营销奖、突破合作伙伴奖等多个奖项。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/NGE3VnHxL.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/Uvo3oO7pv~.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/-b7NM3Aszg.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '具体名单详见下图↓↓↓'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/YqBgpcr0K8.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200831/8a3e1fa14e30dc3be9c5324f604e5991/1wmASgXo0a.jpg'
        ]
      }
    ]
  },
  {
    title: 'B站新添好伙伴！首届效果营销代理商线下培训召开',
    content: [
      {
        type: 'text',
        list: [
          'bilibili好伙伴',
          '——B站首届效果营销代理商线下培训',
          '召开啦！',
          '',
          '2020年7月29日-30日',
          '来自全国的20家代理商参加了培训'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/~MNJSHoUX.jpg']
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/iMBXQ~PSTw.jpg']
      },
      {
        type: 'text',
        list: [
          '培训内容高能不断',
          '涉及美妆行业通案、必选产品赋能、素材审核问题和运营方法论',
          '培训形式别开生面',
          '小组讨论、互动答疑、虚拟提案花样百出'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/NHBO2dHc76.jpg']
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/i4QUR70Zb9.png']
      },
      {
        type: 'text',
        list: [
          '最终13位代理商学员通过考试',
          '获得B站效果广告优化师认证证书'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/i4QUR70Zb9.png']
      },
      {
        type: 'text',
        list: [
          '未来他们将致力于为B站效果营销客户提供更优质的服务'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/iUgI~kDmvE.jpg']
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/a2nifwjBKw.jpg']
      }
    ]
  },
  {
    title: 'B站与拳头游戏达成战略合作，成为英雄联盟赛事独家直播合作平台',
    content: [
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/4v17wBj2St.jpg']
      },
      {
        type: 'text',
        list: [
          '8月1日，哔哩哔哩（以下简称“B站”）与拳头游戏（Riot Games）共同宣布达成英雄联盟全球赛事战略合作。B站正式获得中国大陆地区2020-2022连续三年的全球赛事独家直播版权。',
          '',
          '在上海全球电竞大会上，拳头游戏正式宣布2020英雄联盟全球总决赛将于9月25日至10月31日在中国上海举办，2021年全球总决赛也将于明年再次落地中国。'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/PzbnBIbav~.jpg']
      },
      {
        type: 'text',
        list: [
          '上海浦东足球场效果图（图片来源：@上海发布）',
          '',
          '',
          'B站将独家提供包括全球总决赛、季中冠军赛、全明星赛在内的一系列直播与点播赛事内容。同时在全球总决赛举办期间，B站将制作并上线第二直播厅、赛事点评等独家系列栏目，部分节目将24小时不间断播出。前英雄联盟知名职业选手UZI、Clearlove等多名嘉宾将参与独家栏目。',
          '',
          'B站和拳头游戏还将开展包括英雄联盟电竞赛事纪录片、主题活动在内的一系列深入合作。为了致敬英雄联盟电竞十周年，拳头游戏、腾竞体育、B站将联合出品首部聚焦LPL的官方纪录片，记录LPL队伍征战2020全球总决赛的幕后奋斗历程，该纪录片将在B站独家播出。'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/K237vBVgMC.jpg']
      },
      {
        type: 'text',
        list: [
          '2020英雄联盟全球总决赛主题——“所向无前”（图片来源：@上海发布）',
          '',
          '',
          '在英雄联盟全球总决赛十周年之际，B站和拳头游戏中国也将以电竞为核心主题，从观赛、粉丝互动、沉浸式体验等层面联合独家打造线下庆典，在10月为观众带来丰富的互动机会和电竞节日体验。',
          '',
          '对于此次合作，B站副董事长兼COO李旎表示：“很高兴能与拳头游戏就英雄联盟全球赛事达成战略合作。在过去十年里，我们共同见证了电子竞技从起步到主流，并成为最受中国年轻人欢迎的体育赛事之一。目前，B站电竞内容的累计视频播放量已超440亿次。同时作为上海的互联网公司，B站也将把握机遇，助力上海打造全球电竞之都。”'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/QVtUOgKHrA.jpg']
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Tu-qqDZzPO.jpg']
      }
    ]
  },
  {
    title: '新鲜出炉 | bilibili花火平台开放首周亮点',
    content: [
      {
        type: 'img',
        cls: 'no-gap',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/A~bSbnvTqR.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Tag8U3WNrZ.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/wFvHTXDYzC.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/En87QcBLYg.jpg',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/26MedXXnzv.jpg'
        ]
      }
    ]
  },
  {
    title: '营销快报丨7月这些品牌在B站玩嗨了',
    content: [
      {
        type: 'text',
        list: [
          '夏天，是西瓜、棒冰、盐汽水',
          '也是蝉鸣、沙滩、老蒲扇',
          '而B站的品牌玩家们',
          '也在这个夏天上演了营销大作战',
          '',
          '科颜氏，青春微电影热血来袭',
          '雅诗兰黛，毕业季好礼送不停',
          '芬达，直播首秀新品蓝以置信',
          '莫斯利安，爆款综艺操作神奇',
          '……',
          '',
          '现在，请坐稳扶好，',
          '一起来见证品牌营销夏日名场面吧！',
          ''
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/eU4XtfzANz.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/fCiYDGH7h8.png'
        ]
      },
      {
        type: 'text',
        list: [
          '由科颜氏出品，韩寒监制的',
          '纪实微电影《没在怕的》',
          '邀请B站UP主',
          '@申汤是个嘤嘤怪 @前进的Hania 出镜',
          '记录没在怕的青春故事',
          '',
          '不管你挣扎在梦想与现实的取舍',
          '还是困于自我否定的阴霾',
          '还是深陷流言蜚语千夫所指的境地',
          '只要和热爱、坚持和希望在一起',
          '就没在怕的',
          '',
          '科颜氏给予专业护肤方案',
          '陪伴K星人直面一切，勇敢追梦'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/~sN87ntFap.gif',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/hyz1sAYyei.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/ymY~ZPSHVn.png'
        ]
      },
      {
        type: 'text',
        list: [
          '毕业季怕哭花了妆？',
          '想要在毕业照中留住自己完美的一面？',
          '不怕不怕，DW持妆粉底液来救你~',
          '',
          '雅诗兰黛在B站发起',
          '#百万毕业妆大赏#召集令活动',
          '邀请粉丝晒出自己的毕业照',
          '宠粉抽奖送出DW持妆粉底液',
          '',
          'UP主与用户纷纷创作分享毕业妆容名场面',
          '毕业打卡、泳池挑战、妆容技巧',
          '......',
          '',
          '这个夏天，让DW粉底液承包你的夏日底妆'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Im6R-erytW.gif',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/PYOhVJ4YZ.png',
          'https://activity.hdslb.com/blackboard/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Thz3vJYpj7.gif'
        ]
      },
      {
        type: 'text',
        list: [
          '是它是它就是它！',
          '脑洞节一霸芬达网橘本橘又来啦~',
          '',
          '此次芬达邀请到四位百大UP主',
          '共办一场蓝「芬达」新品云派对',
          '四位蓝朋友为一瓶饮料竟做出这种……',
          '「蓝」以置信！',
          '芬达直播间上演传说中的B站男团之（gong）争（dou）'
          // {
          //   type: 'img',
          //   list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/iZl4L16Jmn.png']
          // },
          // '芬达云派对还有多少惊喜，快来看看：https://www.bilibili.com/video/BV18v411q7jx'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/iZl4L16Jmn.png']
      },
      {
        tyoe: 'text',
        list: ['芬达云派对还有多少惊喜，快来看看：https://www.bilibili.com/video/BV18v411q7jx']
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/2jyIOgKAcc.png']
      },
      {
        type: 'text',
        list: [
          '光明莫斯利安冠名B站综艺《花样实习生》',
          '毒舌阿姨蔡明',
          '霸道总裁吕良伟',
          '最强话痨韩乔生',
          '以“菜鸟”实习生身份加入BW项目组',
          '笑料百出'
        ]
      },
      {
        type: 'img',
        list: [
          'https://activity.hdslb.com/blackboard/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/iFUIEs4HO.gif',
          'https://activity.hdslb.com/blackboard/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/p~rUnoke10.gif'
        ]
      },
      {
        type: 'text',
        list: [
          '什么是“奥力给”？',
          '电脑怎么用呀？',
          '快递怎么寄啊？',
          '银发族与Z世代精彩碰撞不断',
          '',
          '《花样实习生》花样开播',
          '每周五22:00在东方卫视同步播出',
          '截止2020年8月10日B站总播放量已达1395万',
          '追剧人数23万，弹幕总数17.2万'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/ihU2eKyvLD.png']
      },
      {
        type: 'text',
        list: [
          '怎么样，是不是让人“蓝”以置信？',
          '更多花样玩法，',
          '请锁定“哔哩哔哩商业广告”~'
        ]
      },
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/ztFUdptk8z.jpg']
      }
    ]
  },
  {
    title: 'B站营销哪家强，6月商业大赏来康康',
    content: [
      {
        type: 'img',
        list: [require('../assets/images/observe/1.png').default]
      }
    ]
  },
  {
    title: 'B站拍了拍你，请查收这份品牌营销手册',
    content: [
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/26Y5ezqTF.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/N9L8nQ29YH.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/HKX7r~9nOg.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/itvKrPOisJ.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/gNJssdSmvQ.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/kLc~7l1urz.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/cCpNESIRve.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/pzeTN8FgTs.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/EATTrQevs-.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/tQeGLyM3W.JPG',

          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/hPI-LM8lK2.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/ohtoQoG2S.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/vOYuFuLxcM.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/eu4YCI2-Qf.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/bDjJl61I8W.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/-PC-5~pbdc.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/W~N~WRmuWZ.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Nin1WyANcZ.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/b22sJPJgCN.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/oVwXHGdnpW.JPG',

          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/HeSzmTs7Id.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/WQmOvIS0hi.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/aFVw6Ga9u.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/esX4nWb399.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/qzqlu1cJWe.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/~naNM4KH6-.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/1uerAPl2CJ.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/-itqqK7dZM.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/8U-5Q4NbLM.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/YK29xuKfV3.JPG',

          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Ir-AArVz1L.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/pgAmeIeeGb.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/rZxJdbAbBA.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/kkiB1zz40.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/eiFUKGgvDD.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/trmhJhmkIx.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/A4BkrtMcNH.JPG',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/M308IItBab.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '《B站品牌营销手册》',
          '获取方式如下',
          '',
          '扫描上方二维码',
          '关注“哔哩哔哩商业广告”公众号',
          '后台回复“品牌”',
          '即可获取《B站品牌营销手册》PDF版'
        ]
      }
    ]
  },
  {
    title: '一图get新品在B站引爆之路',
    content: [
      {
        type: 'img',
        list: ['https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/9EbKZ3ts5m.jpg']
      }
    ]
  },
  {
    title: '哔哩哔哩花火UP主商业合作平台授权10家核心代理商',
    content: [
      {
        type: 'text',
        list: [
          '哔哩哔哩花火UP主商业合作平台（huahuo.bilibili.com, 以下简称“花火”）现已正式开放，UP主、MCN入驻通道已于7月7日全面开启，品牌主、代理商可自7月17日00：00起，在花火自助完成入驻和下单。',
          '为了给品牌主和UP主提供更加完善、优质的内容营销服务，哔哩哔哩基于对代理商内容营销服务能力的全方位考核，选定了10家代理商作为花火的核心代理商（具体名单见下文）。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/j-jeYx3f4n.jpg'
        ]
      },
      {
        type: 'text',
        list: [
          '图：哔哩哔哩副总裁张振栋(中)和10家花火核心代理商代表',
          ''
        ]
      },
      {
        type: 'text',
        list: [
          '10家核心代理商将立足花火，致力于将UP主内容创作优势与品牌主需求有效结合，为品牌主提供商业策划、项目执行、数据追踪分析等多项整合服务，全面提升合作效率。',
          '',
          '花火10大核心代理商名单如下'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Kyn-8rfMPh.png'
        ]
      }
    ]
  },
  {
    title: '哔哩哔哩花火UP主商业合作平台正式开放',
    content: [
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/ioPw6WBZNG.png'
        ]
      },
      {
        type: 'text',
        list: [
          '哔哩哔哩花火UP主商业合作平台（以下简称“花火”） 正式开放。',
          '花火（huahuo.bilibili.com）是哔哩哔哩服务优质UP主和品牌主的官方商业合作平台，为各方提供安全、规范的商业合作环境和高效、透明的商业交易链路。',
          '基于平台大数据，花火为UP主提供系统报价参考、订单流程管理、平台安全结算等服务，帮助优质UP主更好地实现内容变现，提高创作收入，减少合作纠纷。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/4k0K4-5fsX.png'
        ]
      },
      {
        type: 'text',
        list: [
          '同时，花火为品牌主提供UP主智能推荐、多维数据展示、多项目协同管理等服务，帮助品牌主与UP主更好地进行商业合作，提升合作效率。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/4aOS-fsRrq.png'
        ]
      },
      {
        type: 'text',
        list: [
          '花火UP主、MCN入驻通道已于7月7日22:00全面开启。',
          '品牌主、代理商入驻和投放通道于7月17日00:00起正式开放。',
          '为鼓励更多UP主和品牌主使用花火进行合作，2020年8月1日前，花火限时免收平台服务费。'
        ]
      },
      {
        type: 'img',
        list: [
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/Sc26AxR4Er.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/RQ-E5sfX4q.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/n~tFoOqQlp.png',
          'https://i0.hdslb.com/bfs/activity-plat/static/20200811/8a3e1fa14e30dc3be9c5324f604e5991/tRs8V94myS.jpg'
        ]
      }
    ]
  }
]
