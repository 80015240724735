export const SysInfos = {
  BASE_API_PATH: '/meet/api'
}

export const URLS = {
  recordAccountInfo: {
    type: 'post',
    url: '/open_api/v1/marketing/consult'
  },
  getAccountToken: {
    url: '/open_api/v1/marketing/get/token'
  },
  getLoginStatus: {
    url: 'https://cm.bilibili.com/ad_account/api/web_api/v1/bid_acc/info'
  },
  getIndustries: {
    url: 'https://cm.bilibili.com/meet/api/open_api/v1/marketing/get/industry'
  }
}
